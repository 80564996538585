import { Button, Card, Input, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useCallback } from "react";
import { LocalizationStrings } from "../utils/localization";
import useAxios from "../utils/useAxios";
import { currentLangState } from "./Dashboard";
import { useRecoilValue } from "recoil";

const strings = LocalizationStrings;

const PushNotification = (props) => {
  const [data, setData] = React.useState({
    title: "",
    body: "",
  });

  const currentLang = useRecoilValue(currentLangState);

  strings.setLanguage(currentLang);

  const api = useAxios();

  const handleTitleChange = useCallback((e) => {
    setData((prevData) => ({ ...prevData, title: e.target.value }));
  }, []);

  const handleMessageChange = useCallback((e) => {
    setData((prevData) => ({ ...prevData, body: e.target.value }));
  }, []);

  const handleSubmit = async () => {
    if (data.title === "" || data.body === "") {
      alert("Please fill in all the fields");
      return;
    }
    console.log(data);
    const url = process.env.REACT_APP_BASE_URL.replace(
      "/v1",
      "/admin/notifications"
    );
    const config = {
      baseURL: url,
    };
    const res = await api.post("", data, config);

    console.log(res);
    if (res.status === 201) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.notificationSuccessfullySent
      );
    }
  };

  return (
    <div
      style={{
        width: "300%",
      }}
    >
      <Card title={strings.sendPushNotificationToAll}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography>
            {strings.title}
            <br />
            <Input placeholder={strings.title} onChange={handleTitleChange} />
          </Typography>
          <Typography>
            {strings.message}
            <br />
            <TextArea
              rows={4}
              placeholder={strings.message}
              onChange={handleMessageChange}
            />
          </Typography>

          <Button type="primary" onClick={handleSubmit}>
            {strings.send}
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default PushNotification;
