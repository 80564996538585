import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useRecoilState, atom } from "recoil";
import { accessTokenState, refreshTokenState } from "../App";
import { LocalizationStrings } from "./localization";

const baseURL = process.env.REACT_APP_BASE_URL;

const strings = LocalizationStrings;

export const statusState = atom({
  key: "statusState",
  default: null,
});

const useAxios = () => {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [status, setStatus] = useRecoilState(statusState);

  setAccessToken(localStorage.getItem("bovaAccessToken"));
  setRefreshToken(localStorage.getItem("bovaRefreshToken"));

  const checkJwtExpiration = () => {
    return;
    try {
      const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
      const isExpired = dayjs.unix(user.exp).isBefore(dayjs());
      if (!isExpired) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bovaAccessToken")}`,
    },
  });

  const requestInterceptor = async (req) => {
    setIsRefreshing(false);
    if (checkJwtExpiration()) {
      alert("Your session has expired. Please sign in again.");
      localStorage.removeItem("bovaAccessToken");
      localStorage.removeItem("bovaRefreshToken");
      window.location.href = "/signin";
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    // console.log(localStorage.getItem("bovaAccessToken"));
    let at = localStorage.getItem("bovaAccessToken");

    try {
      const user = jwtDecode(at);
      // console.log("user: ");
      // console.log(user);
      const isExpired = dayjs.unix(user.exp).isBefore(dayjs());
      // console.log("days: " + dayjs.unix(user.exp).isBefore(dayjs()));
      if (!isExpired) {
        return req;
      }
    } catch (error) {
      console.log(error);
    }
    try {
      const response = await axios.post(
        `${baseURL}/auth/token-refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bovaRefreshToken")}`,
          },
        }
      );

      console.log("refreshed");
      setIsRefreshing(true);
      localStorage.setItem(
        "bovaAccessToken",
        response.data.data["accessToken"]
      );
      localStorage.setItem(
        "bovaRefreshToken",
        response.data.data["refreshToken"]
      );

      setAccessToken(response.data.data["accessToken"]);
      setRefreshToken(response.data.data["refreshToken"]);

      req.headers.Authorization = `Bearer ${response.data.data["accessToken"]}`;

      return req;
    } catch (error) {
      if (error.response.status === 401) {
        console.log("401");
        localStorage.removeItem("bovaAccessToken");
        localStorage.removeItem("bovaRefreshToken");
        window.location.href = "/signin";
        alert("Your session has expired. Please sign in again.");
        return req;
      }
    }
  };

  const responseErrorInterceptor = (error) => {
    setStatus(error.response.status);
    if (error.response.status === 404) {
      console.log("404");
      return Promise.reject(error);
    }
    if (error.response.status === 500) {
      console.log("500");
      alert("Server error.");
      return Promise.reject(error);
    }
    if (error.response.status === 400) {
      console.log("400");
      alert(strings.error400);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(requestInterceptor);

  axiosInstance.interceptors.response.use((req) => {
    setStatus(req.status);
    return req;
  }, responseErrorInterceptor);

  // axios.interceptors.request.use(requestInterceptor);

  // axios.interceptors.response.use((req) => {
  //   return req;
  // }, responseErrorInterceptor);

  return axiosInstance;
};

export default useAxios;
