import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { atom, useRecoilState } from "recoil";
import Dashboard from "./Dashboard";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Image from "antd/lib/image";
import HTMLReactParser from "html-react-parser";

function ViewEvent() {
  const [eventData, setEventData] = useState(null);
  let { id } = useParams();
  const api = useAxios();
  const navigate = useNavigate();

  const getEventById = async (id) => {
    try {
      const response = await api.get(`/admin/events/${id}`);
      console.log(response.data.data);
      setEventData(response.data.data);
    } catch (error) {
      console.log(error);
      //   if (error.response.status === 404) {
      //     navigate("/404");
      //   }
    }
  };

  useEffect(() => {
    getEventById(id);
    console.log(eventData);
  }, []);

  return (
    //display the car info here
    <div>
      <div>
        {HTMLReactParser(
          typeof eventData?.textHtml == "string"
            ? eventData?.textHtml
            : "no content"
        )}
      </div>

      {/* <div>Id: {eventData && eventData.id}</div>
      <div>Title: {eventData && eventData.title}</div>
      <div>Description: {eventData && eventData.description}</div>
      <div>Date: {eventData && eventData.date}</div>
      <div>
        Destination:
        {eventData && eventData.destinationName}
      </div>
      <div>Created At: {eventData && eventData.createdAt}</div>
      <div>Updated At: {eventData && eventData.updatedAt}</div>

      <div>
        content:{" "}
        {HTMLReactParser(
          typeof eventData?.textHtml == "string"
            ? eventData?.textHtml
            : "no content"
        )}
      </div>

      <p>Thumbnail: </p>
      <Image src={eventData?.thumbnail.url} /> */}
    </div>
  );
}

export default ViewEvent;
