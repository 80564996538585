import React from "react";
import { Form, Space, DatePicker, Button, Divider, Select } from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import dayjs from "dayjs";

const strings = LocalizationStrings;

const VignetteInputForm = (props) => {
  const api = useAxios();
  const [data, setData] = React.useState({});

  const [form] = Form.useForm();

  const switchDurationType = (type) => {
    switch (type) {
      case "Weekend":
        return strings.weekend;
      case "Week":
        return strings.week;
      case "Month":
        return strings.month;
      case "Three months":
        return strings.threeMonths;

      case "Year":
        return strings.year;

      default:
        break;
    }
  };

  const onFinish = async (values) => {
    const req = {
      startsAt: data?.startsAt,
      durationType: data?.durationType,
      clientCarId: props.edit ? props.clientCarId : props.id,
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/vignettes/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.vignette + strings.updatedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.clientCarId);
      }
    } else {
      const response = await api.post("/admin/vignettes/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.vignette + strings.addedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.id);
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  React.useEffect(() => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          startsAt: "",
          durationType: "",
        });
      } else if (props.edit) {
        api.get("/admin/vignettes/" + props.id).then((res) => {
          //   console.log(res);
          setData(res.data.data);
          console.log(res.data.data);
          form.setFieldsValue({
            startsAt: dayjs(res.data.data.startsAt)
              ? dayjs(res.data.data.startsAt)
              : "",
            durationType: res.data.data.durationType,
          });
        });
      }
    }
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            startsAt: "",
            durationType: "",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={strings.startsAt}
            name="startsAt"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.startsAt.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <DatePicker
              defaultValue=""
              onChange={(e) => {
                setData({ ...data, startsAt: e });
              }}
              value={data?.startsAt}
            />
          </Form.Item>
          <Form.Item
            label={strings.durationType}
            name="durationType"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.durationType.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              placeholder={strings.durationType}
              value={switchDurationType(data?.durationType)}
              options={[
                { value: "Weekend", label: strings.weekend },
                { value: "Week", label: strings.week },
                { value: "Month", label: strings.month },
                { value: "Three months", label: strings.threeMonths },
                { value: "Year", label: strings.year },
              ]}
              style={{ width: "50%" }}
              onChange={(e, o) => {
                setData({
                  ...data,
                  durationType: e,
                  durationTypeLabel: o.label,
                });
              }}
              onSelect={(e, o) => {
                setData({
                  ...data,
                  durationType: e,
                  durationTypeLabel: o.label,
                });
              }}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "90%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default VignetteInputForm;
