import React from "react";
import {
  Form,
  Space,
  DatePicker,
  Button,
  Divider,
  Select,
  InputNumber,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import dayjs from "dayjs";

const strings = LocalizationStrings;

const InsuranceInputForm = (props) => {
  const api = useAxios();
  const [data, setData] = React.useState({});

  const [form] = Form.useForm();

  const switchPaymentsNumber = () => {
    switch (data?.monthsNumber) {
      case "1":
        return [{ value: "1" }];
      case "3":
        return [{ value: "1" }];
      case "6":
        return [{ value: "1" }, { value: "2" }];
      case "9":
        return [{ value: "1" }, { value: "3" }];
      case "12":
        return [{ value: "1" }, { value: "2" }, { value: 4 }];
    }
  };

  const onFinish = async (values) => {
    const req = {
      startsAt: data?.startsAt,
      clientCarId: props.edit ? props.clientCarId : props.id,
      price: data?.price.toString(),
      monthsNumber: parseInt(data?.monthsNumber),
      paymentsNumber: parseInt(data?.paymentsNumber),
      paidPaymentsNumber: data?.paidPaymentsNumber
        ? parseInt(data?.paidPaymentsNumber)
        : 0,
      type: values?.insuranceType,
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/insurances/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.insurance + strings.updatedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.clientCarId);
      }
    } else {
      const response = await api.post("/admin/insurances/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.insurance + strings.addedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.id);
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          startsAt: null,
          monthsNumber: null,
          paymentsNumber: null,
          paidPaymentsNumber: null,
          insuranceType: null,
          price: null,
        });
      } else if (props.edit && props.id) {
        api.get("/admin/insurances/" + props?.id).then((res) => {
          //   console.log(res);
          setData(res.data.data);
          console.log(res.data.data);
          form.setFieldsValue({
            startsAt: dayjs(res.data.data.startsAt),
            monthsNumber: res.data.data.monthsNumber.toString(),
            paymentsNumber: res.data.data.paymentsNumber.toString(),
            paidPaymentsNumber: res.data.data.paidPaymentsNumber.toString(),
            insuranceType: res.data.data.type,
            price: res.data.data.price,
          });
        });
      }
    }
  };

  const selectRef = React.useRef();

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit, props.id]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Divider />
        <Form
          form={form}
          name="basic"
          initialValues={{
            startsAt: "",
            durationType: "",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={strings.brand}
            name="startsAt"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.startsAt.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <DatePicker
              defaultValue=""
              onChange={(e) => {
                setData({ ...data, startsAt: e });
              }}
              value={data?.startsAt}
            />
          </Form.Item>
          <Form.Item
            label={strings.insuranceType}
            name="insuranceType"
            rules={[
              {
                required: true,
                message:
                  strings.pleasInput +
                  strings.insuranceType.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setData({ ...data, insuranceType: e });
              }}
              value={data?.insuranceType}
              options={[
                {
                  label: strings.thirdPartyLiabilityInsurance,
                  value: "Thid party liability insurance",
                },
                {
                  label: strings.comprehensiveInsurance,
                  value: "Comprehensive insurance",
                },
              ]}
              placeholder={strings.insuranceType}
            />
          </Form.Item>
          <Form.Item
            label={strings.monthsNumber}
            name="monthsNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.monthsNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setData({ ...data, monthsNumber: e });
              }}
              value={data?.monthsNumber}
              options={[
                { value: "1" },
                { value: "3" },
                { value: "6" },
                { value: "9" },
                { value: "12" },
              ]}
              placeholder={strings.monthsNumber}
            />
          </Form.Item>
          <Form.Item
            label={strings.paymentsNumber}
            name="paymentsNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.paymentsNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              placeholder={strings.paymentsNumber}
              ref={selectRef}
              value={data?.paymentsNumber}
              size="large"
              style={{ width: "100%" }}
              onSelect={(e) => {
                setData({ ...data, paymentsNumber: e });
              }}
              disabled={data?.monthsNumber ? false : true}
              options={switchPaymentsNumber()}
            />
          </Form.Item>
          <Form.Item
            label={strings.paidPaymentsNumber}
            name="paidPaymentsNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.paidPaymentsNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <InputNumber
              placeholder={strings.paidPaymentsNumber}
              value={data?.paidPaymentsNumber}
              size="large"
              style={{ width: "100%" }}
              onChange={(e) => {
                setData({ ...data, paidPaymentsNumber: e });
              }}
              max={data?.paymentsNumber}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.price}
            name="price"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.price.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <InputNumber
              placeholder={strings.price}
              value={data?.price}
              size="large"
              style={{ width: "100%" }}
              onChange={(e) => {
                setData({ ...data, price: e });
              }}
              min={0}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "90%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default InsuranceInputForm;
