import React, { useEffect, useMemo } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import { Box } from "@mui/system";
import Navbar from "./Navbar";
import { LocalizationStrings } from "../utils/localization";
import { Row, Col, notification, Typography } from "antd";
import bmwlogo from "../assets/images/bmw-logo.svg";
import jwtDecode from "jwt-decode";
import useAxios from "../utils/useAxios";

export const currentLangState = atom({
  key: "currentLangState",
  default: "bg",
});

//lokalizaciq
let strings = LocalizationStrings;

const Context = React.createContext({
  name: "Default",
});

function Dashboard(props) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const axiosApi = useAxios();
  const openNotification = (placement, title, message) => {
    api.info({
      message: title,
      description: <Context.Consumer>{() => message}</Context.Consumer>,
      placement,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  const [user, setUser] = React.useState(null);
  const [currentLang, setCurrentLang] = useRecoilState(currentLangState); // Default language is English (en)
  strings.setLanguage(currentLang);

  const getUserName = async () => {
    const token = localStorage.getItem("bovaAccessToken");

    axiosApi.get("/admin/users/" + jwtDecode(token).sub).then((res) => {
      console.log(res);
      setUser(res.data.data.email);
    });
  };

  useEffect(() => {
    getUserName();
    if (
      localStorage.getItem("bovaAccessToken") === "" ||
      localStorage.getItem("bovaRefreshToken") === "" ||
      localStorage.getItem("bovaAccessToken") === null ||
      localStorage.getItem("bovaRefreshToken") === null ||
      localStorage.getItem("bovaAccessToken") === "undefined" ||
      localStorage.getItem("bovaRefreshToken") === "undefined"
    ) {
      localStorage.removeItem("bovaAccessToken");
      localStorage.removeItem("bovaRefreshToken");
      console.log("aAASAHFLJAHDAJd");
      window.location.href = "/signin";
    }
    //if current address is only dashboard redirect to signin if not authenticated or to client cars
    if (
      props.element === null ||
      props.element === undefined ||
      props.element === ""
    ) {
      if (
        localStorage.getItem("bovaAccessToken") === "" ||
        localStorage.getItem("bovaAccessToken") === null
      ) {
        navigate("/signin");
      } else {
        navigate("/dashboard/client-cars");
      }
    }
  }, [props.currentTab, props.element, navigate]);

  const appendProps = (Component, props) => {
    return React.cloneElement(Component, props);
  };

  return (
    <div>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* <Typography variant="h5" sx={{ ml: 2, mt: 2 }}>
            {strings.panel}
          </Typography> */}
          <img
            src={bmwlogo}
            width={50}
            style={{
              marginLeft: "20px",
              marginRight: "auto",
              marginTop: "15px",
              marginBottom: "auto",
            }}
          />

          <div
            sx={{ ml: "auto", mr: 2, mt: 2 }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" sx={{ mr: 2 }}>
              {user}
            </Typography>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setCurrentLang(currentLang === "bg" ? "en" : "bg");
              }}
            >
              {currentLang}
            </Button>
          </div>
        </Box>
        {/* <Box sx={{ display: "grid", flexDirection: "row" }}> */}
        <Row>
          {
            /* <TabContext value={tabValue}>*/
            <Col span={4}>
              <Box sx={{ p: 3 }}>
                <Navbar />
              </Box>
            </Col>
          }
          <Col span={4}>{appendProps(props.element, { openNotification })}</Col>
        </Row>
        {/* </Box> */}
      </Context.Provider>
    </div>
  );
}

export default Dashboard;
