import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../utils/useAxios";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import dayjs from "dayjs";


function ViewRequestToOrder() {
  const [data, setData] = useState(null);
  let { id } = useParams();
  const api = useAxios();
  const navigate = useNavigate();

  const getDataById = async (id) => {
    try {
      const response = await api.get(`/request-to-buy/${id}`);
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
      //   if (error.response.status === 404) {
      //     navigate("/404");
      //   }
    }
  };

  useEffect(() => {
    getDataById(id);
    console.log(data);
  }, []);

  return (
      //display the car info here
      <div>
        <div>Id: {data && data?.id}</div>
        <div>First Name: {data && data?.firstName}</div>
        <div>Last Name: {data && data?.lastName}</div>
        <div>Email: {data && data?.email}</div>
        <div>Phone: {data && data?.phone}</div>
        <div>Brand: {data && data?.brand}</div>
        <div>Model: {data && data?.model}</div>
        <div>YearOfProductionFrom: {data && dayjs(data?.yearOfProductionFrom).year()}</div>
        <div>YearOfProductionTo: {data && dayjs(data?.yearOfProductionTo).year()}</div>
        <div>Price Range From: {data && data?.priceRangeFrom}</div>
        <div>Price Range To: {data && data?.priceRangeTo}</div>

      </div>
  );
}

export default ViewRequestToOrder;
