import React, { useEffect } from "react";
import { useRecoilState, atom } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { IconButton } from "@mui/material";
import { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Card, Table, Button, Input, Modal, Space } from "antd";
import { Layout } from "antd";

import dayjs from "dayjs";
import LocalizedStrings from "react-localization";

import { currentLangState } from "./Dashboard";

function Vignettes(props) {
  let api = useAxios();

  const strings = new LocalizedStrings({
    en: {
      offers: "Offers",
      title: "Title",
      description: "Description",
      createdAt: "Created At",
      options: "Options",
      edit: "Edit",
      create: "Create offer",
    },

    bg: {
      offers: "Оферти",
      title: "Заглавие",
      description: "Описание",
      createdAt: "Създадена на",
      options: "Опции",
      edit: "Редактирай",
      create: "Създай оферта",
    },
  });

  const [currentLang, setCurrentLang] = useRecoilState(currentLangState); // Default language is English (en

  strings.setLanguage(currentLang);

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [Srows, setRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const [inputData, setInputData] = useState(null);
  //   const [usersDropdown, setUsersDropdown] = useState([]);

  const [inputOpen, setInputOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const newsColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 75,
    },
    {
      title: strings.title,
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100,
    // },
    {
      title: strings.description,
      dataIndex: "description",
      key: "description",
      width: 100,
    },
    {
      title: strings.createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
    },

    {
      title: strings.options,
      key: "options",
      dataIndex: "options",
      width: 150,
      render: (text, record, index) => (
        <>
          <IconButton
            key="edit"
            type="primary"
            className="tag-primary"
            onClick={() => {
              //console log this row
              setEditData(record);
              handleUpdateDialogClickOpen(record.id);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            key="delete"
            type="primary"
            className="tag-primary"
            onClick={() => {
              setDeleteId(record.id);
              handleDeleteButtonClickRow(record);
            }}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton
            key="info"
            type="primary"
            className="tag-primary"
            onClick={() => {
              navigate(`/offers/${record.id}`);
            }}
          >
            <InfoCircleOutlined />
          </IconButton>
        </>
      ),
    },
  ];

  let isMounted = true;

  const fetchData = async (p) => {
    try {
      if (isMounted) {
        // console.log(headerConfig);
        const response = await api.get(`/admin/offers`, {
          params: {
            page: p,
          },
        });
        console.log(response);
        var data = new Array();
        for (let i = 0; i < response.data.data.data.length; i++) {
          data.push(response.data.data.data[i]);
          //add key element to each row
          data[i].key = i;
          data[i].createdAt = dayjs(data[i].createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          // console.log("data: " + i + " " + data);
          // setRows([...Srows, ...data]);
        }
        setRows(data);
        // console.log(Srows);

        // console.log("rows1 \n" + rows1);
      }
    } catch (error) {
      console.error(error);
      // props.logOut();
    }
  };

  useEffect(() => {
    const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
    // console.log(user.role);
    if (user.role !== "admin") {
      alert("not admin");
      props.logOut();
      return;
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    setRefreshToken(localStorage.getItem("bovaRefreshToken"));
    // console.log(accessToken);
    if (
      (localStorage.getItem("bovaAccessToken") === "" &&
        localStorage.getItem("bovaRefreshToken") === "") ||
      (localStorage.getItem("bovaAccessToken") === null &&
        localStorage.getItem("bovaRefreshToken") === null)
    ) {
      navigate("/signin");
    }

    fetchData(page);
    return () => (isMounted = false);
  }, [props.baseUrl]);

  const getDataById = async (id) => {
    const response = await api.get(`/admin/offers/${id}`);
    // console.log(response);

    return response.data;
  };

  const handleUpdateDialogClickOpen = async (id) => {
    // console.log(id);
    let data = await getDataById(id);
    console.log(data);
    setEditData(data);
    setUpdateOpen(true);
  };

  const handleInputDialogClose = () => {
    setInputData({});
    setInputOpen(false);
  };

  const handleUpdateDialogClose = () => {
    setEditData({});
    setUpdateOpen(false);
  };

  const handleInputDialogSubmit = async (event) => {
    if (
      inputData?.title === "" ||
      inputData?.description === "" ||
      inputData?.image === ""
    ) {
      alert("all fields are required");
      return;
    }
    setInputOpen(false);
    const req = {
      title: inputData?.title,
      description: inputData?.description,
      image: inputData?.image,
    };
    console.log(req);
    const response = await api.post("/admin/offers/", req, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    // console.log(response);
    setInputData({});
    fetchData(page);
  };

  const handleNewsUpdateSubmitDialog = async (event) => {
    if (editData?.title === "" || editData?.description === "") {
      alert("all fields are required");
      return;
    }
    setUpdateOpen(false);
    console.log(editData);
    const req = {
      title: editData?.title,
      description: editData?.description,
      image: editData?.image,
    };
    console.log(req);
    const response = await api.put(`/admin/offers/${editData.id}`, req, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response);
    setEditData({
      title: "",
      description: "",
      image: "",
    });
    fetchData(page);
  };

  const handleDeleteButtonClickRow = (cellValues) => {
    // console.log(event);
    setDeleteId(cellValues.id);
    setDeleteOpen(true);
  };

  const handleDeleteDialogCancel = () => {
    setDeleteOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    setDeleteOpen(false);
    const response = await api.delete(`/admin/offers/${deleteId}`);
    console.log(response);
    fetchData(page);
  };

  return (
    <div
      style={{
        width: 1000,
      }}
    >
      <div>
        <Layout
          style={{
            minHeight: "100vh",
            //set a width to the layout
          }}
        >
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title={strings.offers}
                  extra={
                    <Button
                      onClick={() => {
                        setInputOpen(true);
                      }}
                    >
                      {strings.create}
                    </Button>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={newsColumns}
                      dataSource={Srows}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                        current: page,
                        //set number of pages
                        total: total,
                      }}
                      onChange={(pagination) => {
                        setPage(pagination.current);
                        console.log(pagination.current);
                        fetchData(pagination.current);
                      }}
                      size="large"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>

      <Modal
        title={strings.create}
        open={inputOpen}
        onOk={handleInputDialogSubmit}
        onCancel={handleInputDialogClose}
        inputData={inputData}
        width={1000}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Input
            style={{
              color: "black",
            }}
            margin="dense"
            placeholder="Title"
            id="title"
            size="small"
            required
            name="title"
            value={inputData?.title}
            onChange={(e) => {
              setInputData({ ...inputData, title: e.target.value });
            }}
          />
          <Input
            style={{
              color: "black",
            }}
            margin="dense"
            placeholder="Description"
            id="description"
            size="small"
            required
            name="description"
            value={inputData?.description}
            onChange={(e) => {
              setInputData({ ...inputData, description: e.target.value });
            }}
          />
          <input
            style={{
              color: "black",
            }}
            margin="dense"
            type="file"
            onChange={(e) => {
              setInputData({ ...inputData, image: e.target.files[0] });
            }}
          />

          {/* <QuillTextBox stateSet={setInputData} currentState={inputData} /> */}
        </Space>
      </Modal>
      {/* //Edit Input */}
      <Modal
        title={strings.edit}
        open={updateOpen}
        onOk={handleNewsUpdateSubmitDialog}
        onCancel={handleUpdateDialogClose}
        width={1000}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Input
            style={{
              color: "black",
            }}
            margin="dense"
            placeholder="Title"
            id="title"
            size="small"
            required
            name="title"
            value={editData?.title}
            onChange={(e) => {
              setEditData({ ...editData, title: e.target.value });
            }}
          />
          <Input
            style={{
              color: "black",
            }}
            margin="dense"
            placeholder="Description"
            id="description"
            size="small"
            required
            name="description"
            value={editData?.description}
            onChange={(e) => {
              setEditData({ ...editData, description: e.target.value });
            }}
          />
          <input
            style={{
              color: "black",
            }}
            margin="dense"
            type="file"
            onChange={(e) => {
              setEditData({ ...editData, image: e.target.files[0] });
            }}
          />
        </Space>
      </Modal>

      <Modal
        title="Are you sure you want to delete the item?"
        open={deleteOpen}
        onOk={handleDeleteDialogConfirm}
        onCancel={handleDeleteDialogCancel}
      ></Modal>
    </div>
  );
}

export default Vignettes;
