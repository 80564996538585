import React from "react";
import {
  Form,
  Space,
  DatePicker,
  Button,
  Divider,
  Select,
  InputNumber,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import dayjs from "dayjs";

const strings = LocalizationStrings;

const LeaseInputForm = (props) => {
  const api = useAxios();
  const [data, setData] = React.useState({});

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const req = {
      startsAt: data?.startsAt,
      clientCarId: props?.edit ? props?.clientCarId : props?.id,
      price: data?.price?.toString(),
      paymentsNumber: parseInt(data?.paymentsNumber),
      interestRate: data?.interestRate?.toString(),
      residualPayment: data?.residualPayment?.toString(),
      downPayment: data?.downPayment?.toString(),
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/leases/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.lease + strings.updatedSuccessfully
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.id);
      }
    } else {
      const response = await api.post("/admin/leases/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.lease + strings.addedSuccessfully
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.clientCarId);
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          startsAt: "",
          price: "",
          paymentsNumber: "",
          interestRate: "",
          residualPayment: "",
          downPayment: "",
        });
      } else if (props.edit && props.id) {
        api.get("/admin/leases/" + props?.id).then((res) => {
          //   console.log(res);
          setData(res.data.data);
          console.log(res.data.data);
          form.setFieldsValue({
            startsAt: dayjs(res.data.data.startsAt),
            price: res.data.data.price,
            paymentsNumber: res.data.data.paymentsNumber,
            interestRate: res.data.data.interestRate,
            residualPayment: res.data.data.residualPayment,
            downPayment: res.data.data.downPayment,
          });
        });
      }
    }
  };

  const selectRef = React.useRef();

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit, props.id]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Divider />
        <Form
          form={form}
          name="basic"
          initialValues={{
            startsAt: "",
            durationType: "",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={strings.price}
            name="price"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.price.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={strings.price}
              onChange={(e) => {
                setData({ ...data, price: e });
              }}
              value={data?.price}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.startsAt}
            name="startsAt"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.startsAt.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={strings.startsAt}
              onChange={(e) => {
                setData({ ...data, startsAt: e });
              }}
              value={data?.startsAt}
            />
          </Form.Item>
          <Form.Item
            label={strings.paymentsNumber}
            name="paymentsNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.paymentsNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={strings.paymentsNumber}
              onChange={(e) => {
                setData({ ...data, paymentsNumber: e });
              }}
              value={data?.paymentsNumber}
              options={[
                { value: "12" },
                { value: "24" },
                { value: "36" },
                { value: "48" },
                { value: "60" },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            label={strings.interestRate}
            name="interestRate"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.interestRate.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={strings.interestRate}
              onChange={(e) => {
                setData({ ...data, interestRate: e });
              }}
              value={data?.interestRate}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.downPayment}
            name="downPayment"
            rules={[
              {
                required: true,

                message:
                  strings.pleaseInput +
                  strings.downPayment.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={strings.downPayment}
              onChange={(e) => {
                setData({ ...data, downPayment: e });
              }}
              value={data?.downPayment}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.residualPayment}
            name="residualPayment"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.residualPayment.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder={strings.residualPayment}
              onChange={(e) => {
                setData({ ...data, residualPayment: e });
              }}
              value={data?.residualPayment}
              min={0}
            />
          </Form.Item>

          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "90%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default LeaseInputForm;
