import React from "react";
import {
    Form,
    Input,
    Button,
    Space,
    AutoComplete,
    Divider,
    Select,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import TextArea from "antd/es/input/TextArea";

type Props = {
    openNotification: (placement: any, message: any, description: any) => void;
    fetchData: (page: number) => void;
    page: number;
    open: (inputOpen: boolean) => void;
    edit: boolean;
    id: number | null;
};

const strings = LocalizationStrings;

const RequestToOrderForm: React.FC<Props> = (props: Props) => {
    const api = useAxios();
    const [data, setData] = React.useState<any>({});

    const [users, setUsers] = React.useState<any>([]);
    const [usersDropdown, setUsersDropdown] = React.useState<any>([]);

    const [reqState, setReqState] = React.useState<any>({});

    const [form] = Form.useForm();
    const formRef = React.useRef<any>();

    const onFinish = async (values: any) => {
        const req = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            brand: values.brand,
            model: values.model,
            yearOfProductionFrom: values.yearOfProductionFrom || undefined,
            yearOfProductionTo: values.yearOfProductionTo || undefined,
            priceRangeFrom: values.priceRangeFrom || undefined,
            priceRangeTo: values.priceRangeTo || undefined,
        };

        try {
            let response;
            if (props.edit) {
                response = await api.put(`/request-to-buy/${props.id}`, req);
            } else {
                response = await api.post("/request-to-buy/", req);
            }

            if (response.status === 200 || response.status === 201) {
                props.openNotification(
                    "topRight",
                    strings.success,
                    props.edit ? strings.request + strings.updatedSuccessfullyF : strings.request + strings.addedSuccessfullyF
                );
                form.resetFields();
                props.open[1](false);
                props.fetchData(props.page);
            }
        } catch (error) {
            console.error(error);
            props.openNotification("topRight", strings.error, strings.request + strings.failed);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const fetchData = async () => {
        if (props.open[0] === true) {
            if (!props.edit) {
                form.setFieldsValue({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    brand: "",
                    model: "",
                    yearOfProductionFrom: "",
                    yearOfProductionTo: "",
                    priceRangeFrom: "",
                    priceRangeTo: "",
                });
            } else if (props.edit) {
                api.get("/request-to-buy/" + props.id).then((res) => {
                    //   console.log(res.data.data);
                    formRef.current.setFieldsValue({
                        firstName: res.data.data.firstName,
                        lastName: res.data.data.lastName,
                        email: res.data.data.email,
                        phone: res.data.data.phone,
                        brand: res.data.data.brand,
                        model: res.data.data.model,
                        yearOfProductionFrom: res.data.data.yearOfProductionFrom,
                        yearOfProductionTo: res.data.data.yearOfProductionTo,
                        priceRangeFrom: res.data.data.priceRangeFrom,
                        priceRangeTo: res.data.data.priceRangeTo,
                    });
                });
            }
        }
    };

    React.useEffect(() => {
        fetchData();
    }, [props.open, props.edit]);

    return (
        <div>
            <Space
                direction="vertical"
                style={{
                    width: "100%",
                }}
            >
                <Divider />
                <Form
                    form={form}
                    ref={formRef}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    colon={false}
                    labelAlign="left"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item
                        label={strings.firstName}
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: strings.pleaseInput + strings.firstName,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.lastName}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: strings.pleaseInput + strings.lastName,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.email}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: strings.pleaseInput + strings.email,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.phone}
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: strings.pleaseInput + strings.phone,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.brand}
                        name="brand"
                        rules={[
                            {
                                required: true,
                                message: strings.pleaseInput + strings.brand,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.model}
                        name="model"
                        rules={[
                            {
                                required: true,
                                message: strings.pleaseInput + strings.model,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.yearOfProductionFrom}
                        name="yearOfProductionFrom"
                        rules={[
                            {
                                required: false,
                                message: strings.pleaseInput + strings.yearOfProductionFrom,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.yearOfProductionTo}
                        name="yearOfProductionTo"
                        rules={[
                            {
                                required: false,
                                message: strings.pleaseInput + strings.yearOfProductionTo,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.priceRangeFrom}
                        name="priceRangeFrom"
                        rules={[
                            {
                                required: false,
                                message: strings.pleaseInput + strings.priceRangeFrom,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={strings.priceRangeTo}
                        name="priceRangeTo"
                        rules={[
                            {
                                required: false,
                                message: strings.pleaseInput + strings.priceRangeTo,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Divider />
                    <Form.Item>
                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "50px",
                                    position: "relative",
                                    left: "90%",
                                    transform: "translateX(-50%)",
                                }}
                            >
                                {strings.submit}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Space>
        </div>
    );
};

export default RequestToOrderForm;
