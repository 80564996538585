import React, { useEffect } from "react";
import { useRecoilState, atom } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Modal,
  Space,
  Upload,
  Divider,
  DatePicker,
  Checkbox,
} from "antd";
import { Layout } from "antd";
import Item from "antd/es/list/Item";
import QuillTextBox from "./QuillTextBox.tsx";
import { Quill } from "react-quill";
import { currentLangState } from "./Dashboard";
import LocalizedStrings from "react-localization";
import dayjs from "dayjs";
import HTMLReactParser from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { quillObjState } from "./QuillTextBox.tsx";
import { LocalizationStrings } from "../utils/localization";
import axios from "axios";

const NewsRowsState = atom({
  key: "NewsRowsState",
  default: [],
});

const NewsSelectedRowState = atom({
  key: "NewsSelectedRowState",
  default: null,
});

const NewsTabState = atom({
  key: "NewsTabState",
  default: "1",
});

export const editNewsState = atom({
  key: "editNewsState",
  default: null,
});

export const inputNewsState = atom({
  key: "inputNewsState",
  default: {
    title: "",
    textDelta: {},
    textHtml: "",
  },
});

function News(props) {
  let api = useAxios();

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [Srows, setRows] = useRecoilState(NewsRowsState);
  const [selectedRow, setSelectedRow] = useRecoilState(NewsSelectedRowState);
  const [editNews, setEditNews] = useRecoilState(editNewsState);
  const [inputNews, setInputNews] = useRecoilState(inputNewsState);
  const [currentLang, setCurrentLang] = useRecoilState(currentLangState);
  const [notify, setNotify] = useState(false);
  //   const [usersDropdown, setUsersDropdown] = useState([]);

  const [inputOpen, setInputOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState("");

  const [quillText, setQuillText] = useRecoilState(quillObjState);

  const navigate = useNavigate();

  const strings = LocalizationStrings;
  strings.setLanguage(currentLang);

  const stylesString = `/* Global styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Body styles */
  body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #eee;
    background-color: #222;
  }
  span {
    color: #eee !important;
  }
  strong {
    color: #eee !important;
  }
  i {
    color: #eee !important;
  }
  u {
    color: #eee !important;
  }
  q {
    color: #eee !important;
  }
  p {
    color: #eee !important;
  }
  
  /* Article container styles */
  .article {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #333;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  }
  
  /* Headline styles */
  .article h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
  }
  
  .article h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
  }
  
  /* Paragraph styles */
  .article p {
    margin-bottom: 20px;
    text-align: justify;
    color: #ddd;
  }
  
  /* Image styles */
  .article img {
    display: block;
    max-width: 100%;
    margin: 20px auto;
  }
  
  /* Strong styles */
  .article strong {
    font-weight: bold;
    color: #fff;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 1200px) {
    /* Body styles */
    body {
      font-size: 14px;
    }
    
    /* Article container styles */
    .article {
      max-width: none;
      padding: 15px;

    }
  
    /* Headline styles */
    .article h1 {
      font-size: 28px;
      margin-bottom: 15px;
    }
  
    .article h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }
  
    /* Paragraph styles */
    .article p {
      margin-bottom: 15px;
    }
  
    /* Image styles */
    .article img {
      display: block;
    max-width: 100%;
    margin: 15px auto;
    }
  }
  `;

  const styles = {
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    body: {
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      lineHeight: 1.5,
      color: "#333",
      backgroundColor: "#f1f1f1",
    },
    ".article": {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "30px",
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    ".article h1": {
      fontSize: "36px",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
    },
    ".article h2": {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    ".article p": {
      marginBottom: "20px",
      textAlign: "justify",
    },
    ".article img": {
      display: "block",
      maxWidth: "100%",
      margin: "20px auto",
    },
    ".article strong": {
      fontWeight: "bold",
    },
  };

  const newsColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 75,
    },
    {
      title: strings.title,
      dataIndex: "title",
      key: "title",
      width: 200,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record, index) => {
        if (text.length > 50) {
          return (
            <Tooltip title={<span>{text}</span>}>
              <span>{text.slice(0, 50)}...</span>
            </Tooltip>
          );
        } else {
          return <span>{text}</span>;
        }
      },
    },
    {
      title: strings.date,
      dataIndex: "date",
      key: "date",
      width: 200,
      render: (text, record, index) => (
        <>{record.date ? dayjs(record.date).format("DD/MM/YYYY") : ""}</>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100,
    // },
    {
      title: strings.createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
    },

    {
      title: strings.options,
      key: "options",
      dataIndex: "options",
      width: 150,
      render: (text, record, index) => (
        <>
          <IconButton
            key="edit"
            type="primary"
            className="tag-primary"
            onClick={() => {
              //console log this row
              setEditNews(record);
              handleUpdateDialogClickOpen(record.id);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            key="delete"
            type="primary"
            className="tag-primary"
            onClick={() => {
              setDeleteId(record.id);
              handleDeleteButtonClickRow(record);
            }}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton
            key="info"
            type="primary"
            className="tag-primary"
            onClick={() => {
              navigate(`/news/${record.id}`);
            }}
          >
            <InfoCircleOutlined />
          </IconButton>
        </>
      ),
    },
  ];

  let isMounted = true;

  const fetchData = async (p, isEmpty = false) => {
    try {
      if (isMounted) {
        // console.log(headerConfig);
        const response = await api.get(`/admin/news`, {
          params: {
            page: p,
            limit: 10,
            search: isEmpty ? "" : search,
            sortBy: "createdAt:DESC",
          },
        });
        console.log(response);
        var data = new Array();
        setTotal(response.data.data.meta.totalItems);

        for (let i = 0; i < response.data.data.data.length; i++) {
          data.push(response.data.data.data[i]);
          //add key element to each row
          data[i].key = i;
          data[i].createdAt = dayjs(data[i].createdAt).format(
            "DD/MM/YYYY HH:mm"
          );
          // console.log("data: " + i + " " + data);
          // setRows([...Srows, ...data]);
        }
        setRows(data);
        // console.log(Srows);

        // console.log("rows1 \n" + rows1);
      }
    } catch (error) {
      console.error(error);
      // props.logOut();
    }
  };

  useEffect(() => {
    const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
    // console.log(user.role);
    if (user.role !== "admin") {
      alert("not admin");
      props.logOut();
      return;
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    setRefreshToken(localStorage.getItem("bovaRefreshToken"));
    // console.log(accessToken);
    if (
      (localStorage.getItem("bovaAccessToken") === "" &&
        localStorage.getItem("bovaRefreshToken") === "") ||
      (localStorage.getItem("bovaAccessToken") === null &&
        localStorage.getItem("bovaRefreshToken") === null)
    ) {
      navigate("/signin");
    }

    fetchData(page);
    return () => (isMounted = false);
  }, [props.baseUrl]);

  //   const getUsers = async () => {
  //     const response = await api.get("/admin/users");
  //     // console.log(response);
  //     return response.data.data.data;
  //   };

  //   const getUserEmails = () => {
  //     let data = [];
  //     for (let i = 0; i < usersDropdown.length; i++) {
  //       data.push(usersDropdown[i].email);
  //     }
  //     return data;
  //   };

  const getNewsById = async (id) => {
    const response = await api.get(`/admin/news/${id}`);
    // console.log(response);

    return response.data.data;
  };

  const handleUpdateDialogClickOpen = async (id) => {
    // console.log(id);
    setQuillText(null);
    let data = await getNewsById(id);
    console.log(data);
    // setEditNews(null);
    setQuillText(JSON.parse(data?.textDelta));
    setEditNews(data);
    setUpdateOpen(true);
  };

  const handleInputDialogClose = () => {
    setInputNews({});
    setInputOpen(false);
  };

  const handleUpdateDialogClose = () => {
    setEditNews({});
    setUpdateOpen(false);
  };

  const SendNotification = async (title, body) => {
    const url = process.env.REACT_APP_BASE_URL?.replace(
      "/v1",
      "/admin/notifications"
    );
    const config = {
      baseURL: url,
    };

    const res = await api.post(
      "",
      {
        title: title,
        body: body,
      },
      config
    );
    console.log(res);
    return res;
  };

  const handleInputDialogSubmit = async (event) => {
    if (inputNews?.title === "" || inputNews?.textDelta === "") {
      alert("all fields are required");
      return;
    }
    setInputOpen(false);

    const htmlStyled = (
      <div style={styles}>
        <div className="article">
          {/* <h1>{newsData && newsData.title}</h1> */}
          {HTMLReactParser(
            typeof inputNews?.textHtml == "string"
              ? inputNews?.textHtml
              : "no content"
          )}
        </div>
      </div>
    );
    const req = {
      title: inputNews?.title,
      // description: inputNews?.description,
      // image: inputNews?.image,
      textDelta: JSON.stringify(inputNews?.textDelta),
      date: inputNews?.date,
      textHtml: getStyledHtmlString(
        inputNews?.textHtml,
        stylesString,
        inputNews?.title
      ),
      thumbnail: inputNews?.thumbnail,
    };
    console.log(req);
    const response = await api.post("/admin/news/", req);
    if (response.status === 201) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.newsSingle + " " + strings.createdSuccessfullyF
      );
      if (notify) {
        await SendNotification(inputNews?.title, inputNews?.title);
      }
    }
    // console.log(response);
    setInputNews({});
    setNotify(false);
    fetchData(page);
  };

  const getStyledHtmlString = (html, styles, title) => {
    const htmlStyled = `<!DOCTYPE html>
    <html>
      <body>
      <style>
          ${styles}
        </style>
        <div class="article">
        ${html}
        </div>
      </body>
    </html>`;
    return htmlStyled.replace(/\n/g, "");
  };

  const handleNewsUpdateSubmitDialog = async (event) => {
    if (editNews?.title === "" || editNews?.description === "") {
      alert("all fields are required");
      return;
    }
    setUpdateOpen(false);
    console.log(editNews);

    const htmlStyled = (
      <div style={styles}>
        <div className="article">
          {/* <h1>{newsData && newsData.title}</h1> */}
          {HTMLReactParser(
            typeof editNews?.textHtml == "string"
              ? editNews?.textHtml
              : "no content"
          )}
        </div>
      </div>
    );
    const req = {
      title: editNews?.title,
      // description: inputNews?.description,
      // image: inputNews?.image,
      textDelta: JSON.stringify(editNews?.textDelta),
      date: editNews?.date,
      textHtml: getStyledHtmlString(
        editNews?.textHtml,
        stylesString,
        editNews?.title
      ),
      thumbnail: editNews?.thumbnail,
    };
    console.log(req);
    const response = await api.put(`/admin/news/${editNews.id}`, req);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.newsSingle + " " + strings.updatedSuccessfullyF
      );
      if (notify) {
        await SendNotification(editNews?.title, editNews?.title);
      }
    }
    console.log(response);
    setEditNews({});
    setNotify(false);
    fetchData(page);
  };

  const handleDeleteButtonClickRow = (cellValues) => {
    // console.log(event);
    setDeleteId(cellValues.id);
    console.log("Delete Row " + selectedRow);
    setDeleteOpen(true);
  };

  const handleDeleteDialogCancel = () => {
    setDeleteOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    setDeleteOpen(false);
    const response = await api.delete(`/admin/news/${deleteId}`);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.newsSingle + " " + strings.deletedSuccessfullyF
      );
    }
    console.log(response);
    fetchData(page);
  };

  const imageHandler = async (e, o) => {
    const file = e.file;
    if (file) {
      const fileUploadUrl = "admin/file-upload/upload-signed-url";

      var fileName = file.name;

      //make a get request with the file name and type
      const res = await api.get(fileUploadUrl, {
        params: {
          filename: fileName,
          mediaType: file.type,
        },
      });
      console.log(res.data);
      const url = res.data.data.url;
      const newFileName = res.data.data.filename;
      const res2 = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      console.log(res2);

      const res3 = await api.get("admin/file-upload/public-url?", {
        params: {
          filename: newFileName,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // console.log(res3);
      var fetchImageUrl = res3.data.data.url;
      var fetchImageKey = res.data.data.filename;
      if (res3.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.imageUploadedSuccessfully
        );
      }

      console.log(fetchImageUrl);
      if (o === "input") {
        setInputNews({
          ...inputNews,
          thumbnail: {
            key: fetchImageKey,
            url: fetchImageUrl,
          },
        });
      } else if (o === "edit") {
        setEditNews({
          ...editNews,
          thumbnail: {
            key: fetchImageKey,
            url: fetchImageUrl,
          },
        });
      }
    }
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleRemove = (file) => {
    console.log(file);
  };

  return (
    <div
      style={{
        width: 1000,
      }}
    >
      <div>
        <Layout
          style={{
            minHeight: "100vh",
            //set a width to the layout
          }}
        >
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title={strings.news}
                  extra={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        placeholder={strings.search}
                        prefix={<SearchOutlined />}
                        size="small"
                        style={{
                          marginRight: 25,
                        }}
                        onPressEnter={(event) => {
                          setSearch(event.target.value);
                          fetchData(page);
                        }}
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                          if (event.target.value === "" || null) {
                            setSearch(null);
                            fetchData(page, true);
                          }
                        }}
                        value={search}
                      />
                      <Button
                        onClick={() => {
                          setInputOpen(true);
                          setQuillText(null);
                        }}
                      >
                        {strings.createNews}
                      </Button>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={newsColumns}
                      dataSource={Srows}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                        current: page,
                        //set number of pages
                        total: total,
                      }}
                      onChange={(pagination) => {
                        setPage(pagination.current);
                        console.log(pagination.current);
                        fetchData(pagination.current);
                      }}
                      size="large"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>
      {/* <Container fixed>
        <div style={{ height: 540, width: "450%" }}>
          <DataGrid
            getRowId={(row) => row.id}
            rows={Srows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            disableSelectionOnClick
          />
        </div>
      </Container> */}
      {/* <Container fixed>
        <Button
          sx={{ mt: 2 }}
          onClick={() => {
            setInputOpen(true);
          }}
        >
          Create News
        </Button>
      </Container> */}
      {/* //Modal Input */}
      <Modal
        title={strings.createNews}
        open={inputOpen}
        onOk={handleInputDialogSubmit}
        onCancel={handleInputDialogClose}
        inputNews={inputNews}
        width={1000}
      >
        <Divider />
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Typography>
            {strings.title} <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.title}
              id="title"
              size="small"
              required
              name="title"
              value={inputNews?.title}
              onChange={(e) => {
                setInputNews({ ...inputNews, title: e.target.value });
              }}
            />
          </Typography>
          {/* <Input
            margin="dense"
            placeholder="Description"
            id="description"
            size="small"
            required
            name="description"
            value={inputNews?.description}
            onChange={(e) => {
              setInputNews({ ...inputNews, description: e.target.value });
            }}
          /> */}
          <Typography>
            {strings.date}
            <br />
            <DatePicker
              style={{
                width: "100%",
              }}
              value={inputNews?.date ? dayjs(inputNews?.date) : null}
              onChange={(date, dateString) => {
                setInputNews({ ...inputNews, date: dateString });
              }}
            />
          </Typography>
          <Typography>
            {strings.content} <br />
            <QuillTextBox
              stateSet={setInputNews}
              currentState={inputNews}
              actionType="inputNews"
            />
          </Typography>
          <Typography>
            Thumbnail: <br />
            {/* <input
              type="file"
              name="image"
              onChange={(e) => {
                imageHandler(e, "input");
              }}
            /> */}
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(file) => {
                imageHandler(file, "input");
              }}
              beforeRequest={false}
              onRemove={(file) => {
                handleRemove(file);
              }}
              // onChange={imageHandler}
            >
              {inputNews?.thumbnail ? (
                <img
                  src={inputNews?.thumbnail?.url}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Typography>
          <Typography>
            <Checkbox
              checked={notify}
              onChange={(e) => {
                setNotify(e.target.checked);
              }}
            >
              {strings.notifyUsers}
            </Checkbox>
          </Typography>
        </Space>
      </Modal>
      {/* //Edit Input */}
      <Modal
        title={strings.edit}
        open={updateOpen}
        onOk={handleNewsUpdateSubmitDialog}
        onCancel={handleUpdateDialogClose}
        width={1000}
      >
        <Divider />
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Typography>
            {strings.title}
            <br />
            <Input
              margin="dense"
              placeholder={strings.title}
              id="title"
              size="small"
              required
              name="title"
              value={editNews?.title}
              style={{
                color: "black",
              }}
              onChange={(e) => {
                setEditNews({ ...editNews, title: e.target.value });
              }}
            />
          </Typography>
          {/* <Input
            fullWidth
            multiline
            margin="dense"
            placeholder="Description"
            id="description"
            size="small"
            required
            name="description"
            value={editNews?.description}
            onChange={(e) => {
              setEditNews({ ...editNews, description: e.target.value });
            }}
          /> */}
          <Typography>
            {strings.date}
            <br />
            <DatePicker
              style={{
                width: "100%",
              }}
              value={editNews?.date ? dayjs(editNews?.date) : null}
              onChange={(date, dateString) => {
                setEditNews({ ...editNews, date: dateString });
              }}
            />
          </Typography>

          <Typography>
            {strings.content}
            <br />
            <QuillTextBox
              stateSet={setEditNews}
              currentState={editNews}
              value={editNews?.textDelta}
              actionType="editNews"
            />
          </Typography>
          <Typography>
            Thumbnail: <br />
            {/* <input
              type="file"
              name="image"
              onChange={(e) => {
                imageHandler(e, "edit");
              }}
            /> */}
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(file) => {
                imageHandler(file, "edit");
              }}
              beforeRequest={false}
              onRemove={(file) => {
                handleRemove(file);
              }}
              // onChange={imageHandler}
            >
              {editNews?.thumbnail ? (
                <img
                  src={editNews?.thumbnail?.url}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Typography>
          <Typography>
            <Checkbox
              checked={notify}
              onChange={(e) => {
                setNotify(e.target.checked);
              }}
            >
              {strings.notifyUsers}
            </Checkbox>
          </Typography>
        </Space>
      </Modal>

      <Modal
        title={strings.deleteConfirmation}
        open={deleteOpen}
        onOk={handleDeleteDialogConfirm}
        onCancel={handleDeleteDialogCancel}
      ></Modal>
    </div>
  );
}

export default News;
