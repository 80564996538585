import React from "react";
import {
  Form,
  Input,
  Button,
  Space,
  AutoComplete,
  Divider,
  Upload,
  InputNumber,
  Select,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/css";
//TODO: fix crete form ui
type Props = {
  openNotification: (placement: any, message: any, description: any) => void;
  fetchData: (page: number) => void;
  page: number;
  open: (inputOpen: boolean) => void;
  edit: boolean;
  id: number | null;
};

const strings = LocalizationStrings;

const ShowroomCarsInputForm: React.FC<Props> = (props: Props) => {
  const api = useAxios();
  const [data, setData] = React.useState<any>({});
  const [fileList, setFileList] = React.useState<any>([]);
  const [videoList, setVideoList] = React.useState<any>([]);

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    if (videoList.at(0)) {
      videoList.at(0).isVideo = true;
    }
    const combinedFiles = [...fileList, ...videoList];

    const req = {
      title: data?.title,
      description: data?.description,
      price: data?.price?.toString(),
      engineDisplacement: data?.engineDisplacement?.toString(),
      enginePower: data?.enginePower?.toString(),
      engineType: data?.engineType,
      transmissionType: data?.transmissionType,
      category: data?.category,
      productionYear: data?.productionYear?.toString(),
      mileage: data?.mileage?.toString(),
      images: combinedFiles.map((file) => ({
        id: file.id || null,
        url: file.url,
        key: file.name,
        isVideo: file.isVideo || false,
        createdAt: file.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      })),
    };

    console.log(req);

    try {
      if (props.edit) {
        const response = await api.put(`/admin/showroom-cars/${props.id}`, req);
        if (response.status === 200) {
          props.openNotification(
              "topRight",
              strings.success,
              strings.car + strings.updatedSuccessfullyF
          );
          props.fetchData(props.page);
          props.open(false); // Close the form automatically after a successful edit
        }
      } else {
        const response = await api.post("/admin/showroom-cars/", req);
        if (response.status === 201) {
          props.openNotification(
              "topRight",
              strings.success,
              strings.car + strings.addedSuccessfullyF
          );
          props.fetchData(props.page);
          props.open(false); // Close the form automatically after a successful add
        }
      }
    } catch (error) {
      console.error("Request failed: ", error);
    } finally {
      form.resetFields();
      setData({});
      setFileList([]);
      setVideoList([]);
    }
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      if (!props.edit) {
        // Prepopulate empty form for adding a new entry
        form.setFieldsValue({
          title: "",
          description: "",
          price: "",
          engineDisplacement: "",
          enginePower: "",
          engineType: "",
          transmissionType: "",
          category: "",
          productionYear: "",
          mileage: "",
          images: "",
          video: ""
        });
        setFileList([]);
        setVideoList([]);
      } else if (props.edit) {
        api.get("/admin/showroom-cars/" + props.id).then((res) => {
          console.log(res);
          setData({ ...res.data.data });
          let images = [];
          let videos = [];
          res.data.data.images.forEach((media) => {
            if (media.isVideo) {
              videos.push(media);
            } else {
              images.push(media);
            }
          });

          setFileList(
              images.map((image: any) => ({
                url: image.url,
                name: image.key,
                id: image.id,
                createdAt: image.createdAt,
                updatedAt: image.updatedAt,
              }))
          );
          setVideoList(
              videos.map((video: any) => ({
                url: video.url,
                name: video.key,
                id: video.id,
                createdAt: video.createdAt,
                updatedAt: video.updatedAt,
                isVideo: true,
              }))
          );

          form.setFieldsValue({
            title: res.data.data.title,
            description: res.data.data.description,
            price: res.data.data.price,
            engineDisplacement: res.data.data.engineDisplacement,
            enginePower: res.data.data.enginePower,
            engineType: res.data.data.engineType,
            transmissionType: res.data.data.transmissionType,
            category: res.data.data.category,
            productionYear: res.data.data.productionYear,
            mileage: res.data.data.mileage,
            images: res.data.data.images.map((image: any) => ({
              url: image.url,
              name: image.key,
            })),
          });
        });
      }
    } else {
      setData({});
      setFileList([]);
      setVideoList([]);
    }
  };


  const handleRemove = (file) => {
    console.log(file);
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
  };

  const handleVideoRemove = (file) => {
    console.log(file);
    const index = videoList.indexOf(file);
    const newVideoList = videoList.slice();
    newVideoList.splice(index, 1);
    setVideoList(newVideoList);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const imageHandler = async (e) => {
    console.log(e);
    const file = e.file;
    if (file) {
      const fileUploadUrl = "admin/file-upload/upload-signed-url";

      var fileName = file.name;

      //make a get request with the file name and type
      const res = await api.get(fileUploadUrl, {
        params: {
          filename: fileName,
          mediaType: file.type,
        },
      });
      console.log(res.data);
      const url = res.data.data.url;
      const newFileName = res.data.data.filename;
      const res2 = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      console.log(res2);

      const res3 = await api.get("admin/file-upload/public-url?", {
        params: {
          filename: newFileName,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bovaAccessToken")}`,
        },
      });
      if (res3.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.image + strings.imageUploadedSuccessfully
        );
      }
      // console.log(res3);
      var fetchImageUrl = res3.data.data.url;
      var fetchImageKey = res.data.data.filename;
      console.log(fetchImageUrl);
      setFileList([
        ...fileList,
        {
          uid: file.uid,
          name: fetchImageKey,
          status: "done",
          url: fetchImageUrl,
        },
      ]);
    }
  };
  const videoeHandler = async (e) => {
    console.log(e);
    const file = e.file;
    if (file) {
      const fileUploadUrl = "admin/file-upload/upload-signed-url";

      var fileName = file.name;

      //make a get request with the file name and type
      const res = await api.get(fileUploadUrl, {
        params: {
          filename: fileName,
          mediaType: file.type,
        },
      });
      console.log(res.data);
      const url = res.data.data.url;
      const newFileName = res.data.data.filename;
      const res2 = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      console.log(res2);

      const res3 = await api.get("admin/file-upload/public-url?", {
        params: {
          filename: newFileName,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bovaAccessToken")}`,
        },
      });
      if (res3.status === 200) {
        props.openNotification(
            "topRight",
            strings.success,
            strings.image + strings.videoUploadedSuccessfully
        );
      }
      // console.log(res3);
      var fetchImageUrl = res3.data.data.url;
      var fetchImageKey = res.data.data.filename;
      console.log(fetchImageUrl);
      setVideoList([
        ...videoList,
        {
          uid: file.uid,
          name: fetchImageKey,
          status: "done",
          url: fetchImageUrl,
        },
      ]);
    }
  };


  const videoHandler = async (e) => {
    console.log(e);
    const file = e.file;
    if (file) {
      const fileUploadUrl = "admin/file-upload/upload-signed-url";

      var fileName = file.name;

      //make a get request with the file name and type
      const res = await api.get(fileUploadUrl, {
        params: {
          filename: fileName,
          mediaType: file.type,
        },
      });
      console.log(res.data);
      const url = res.data.data.url;
      const newFileName = res.data.data.filename;
      const res2 = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      console.log(res2);

      const res3 = await api.get("admin/file-upload/public-url?", {
        params: {
          filename: newFileName,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bovaAccessToken")}`,
        },
      });
      if (res3.status === 200) {
        props.openNotification(
            "topRight",
            strings.success,
            strings.image + strings.imageUploadedSuccessfully
        );
      }
      // console.log(res3);
      var fetchImageUrl = res3.data.data.url;
      var fetchImageKey = res.data.data.filename;
      console.log(fetchImageUrl);
      setVideoList([
        ...videoList,
        {
          uid: file.uid,
          name: fetchImageKey,
          status: "done",
          url: fetchImageUrl,
        },
      ]);
    }
  };

  const handleRemoveVideo = (file) => {
    console.log(file);
    const index = videoList.indexOf(file);
    const newVideoList = videoList.slice();
    newVideoList.splice(index, 1);
    setVideoList(newVideoList);
  };


  const DraggableUploadListItem = ({ originNode, file }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: file.uid,
    });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "move",
      width: "100%",
      height: "100%",
    };
    const className = isDragging
      ? css`
          a {
            pointer-events: none;
          }
        `
      : "";
    return (
      <div
        ref={setNodeRef}
        style={style}
        className={className}
        {...attributes}
        {...listeners}
      >
        {/* hide error tooltip when dragging */}
        {file.status === "error" && isDragging
          ? originNode.props.children
          : originNode}
      </div>
    );
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  const onDragEndVideo = ({ active, over }) => {
    if (active.id !== over?.id) {
      setVideoList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Divider />
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label={strings.title}
            name="title"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.title.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <Input
              placeholder={strings.title}
              value={data?.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label={strings.description}
            name="description"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.description.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <TextArea
              placeholder={strings.description}
              rows={3}
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label={strings.price}
            name="price"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.price.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <InputNumber
              placeholder={strings.price}
              style={{
                width: "100%",
              }}
              value={data?.price}
              onChange={(e) => setData({ ...data, price: e })}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.category}
            name="category"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.category.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              placeholder={strings.category}
              options={[
                { value: "Sedan" },
                { value: "Hatchback" },
                { value: "SUV" },
                { value: "Coupe" },
                { value: "Convertible" },
                { value: "Wagon" },
                { value: "Minivan" },
                { value: "Pickup" },
              ]}
              value={data?.category}
              onChange={(e) => setData({ ...data, category: e })}
            />
          </Form.Item>
          <Form.Item
            label={strings.engineType}
            name="engineType"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.engineType.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              placeholder={strings.engineType}
              options={[
                { value: "Gas" },
                { value: "Diesel" },
                { value: "Hybrid" },
                { value: "Electric" },
              ]}
              value={data?.engineType}
              onChange={(e) => setData({ ...data, engineType: e })}
            />
          </Form.Item>
          <Form.Item
            label={strings.transmissionType}
            name="transmissionType"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.transmissionType.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              placeholder={strings.transmissionType}
              options={[{ value: "Automatic" }, { value: "Manual" }]}
              value={data?.transmissionType}
              onChange={(e) => setData({ ...data, transmissionType: e })}
            />
          </Form.Item>
          <Form.Item
            label={strings.enginePower}
            name="enginePower"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.enginePower.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <InputNumber
              placeholder={strings.enginePower}
              style={{
                width: "100%",
              }}
              value={data?.enginePower}
              onChange={(e) => setData({ ...data, enginePower: e })}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.engineDisplacement}
            name="engineDisplacement"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber
              placeholder={strings.engineDisplacement}
              style={{
                width: "100%",
              }}
              value={data?.engineDisplacement}
              onChange={(e) => setData({ ...data, engineDisplacement: e })}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.productionYear}
            name="productionYear"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber
              placeholder={strings.productionYear}
              style={{
                width: "100%",
              }}
              value={data?.productionYear}
              onChange={(e) => setData({ ...data, productionYear: e })}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.mileage}
            name="mileage"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <InputNumber
              placeholder={strings.mileage}
              style={{
                width: "100%",
              }}
              value={data?.mileage}
              onChange={(e) => setData({ ...data, mileage: e })}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={strings.image}
            name="image"
            rules={[
              {
                required: true,
                //check filelist length to be more than 0
                validator: (rule, value) => {
                  if (fileList.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    strings.pleaseInput +
                      strings.image.toLocaleLowerCase() +
                      "!"
                  );
                },

                message:
                  strings.pleaseInput + strings.image.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
              <SortableContext
                items={fileList.map((i) => i.uid)}
                strategy={verticalListSortingStrategy}
              >
                <Upload
                  // action={(file) => {
                  //   imageHandler(file);
                  // }}
                  customRequest={(file) => {
                    imageHandler(file);
                  }}
                  onRemove={(file) => {
                    handleRemove(file);
                  }}
                  listType="picture-card"
                  fileList={fileList}
                  itemRender={(originNode, file) => (
                    <DraggableUploadListItem
                      originNode={originNode}
                      file={file}
                    />
                  )}
                  // onChange={imageHandler}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
              </SortableContext>
            </DndContext>
          </Form.Item>

          <Form.Item
              label={strings.video}
              name="video"
          >
            <DndContext sensors={[sensor]} onDragEnd={onDragEndVideo}>
              <SortableContext
                  items={videoList.map((i) => i.uid)}
                  strategy={verticalListSortingStrategy}
              >
                <Upload
                    customRequest={(file) => {
                      videoeHandler(file);
                    }}
                    onRemove={(file) => {
                      handleRemoveVideo(file);
                    }}
                    listType="picture-card"
                    fileList={videoList}
                    itemRender={(originNode, file) => (
                        <DraggableUploadListItem
                            originNode={originNode}
                            file={file}
                        />
                    )}
                >
                  {videoList.length >= 2 ? null : uploadButton}
                </Upload>
              </SortableContext>
            </DndContext>
          </Form.Item>

          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "130%",
                  transform: "translateX(-10%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>

        </Form>
      </Space>
    </div>
  );
};

export default ShowroomCarsInputForm;
