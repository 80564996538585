import React from "react";
import {
  Form,
  Input,
  Button,
  Space,
  AutoComplete,
  Divider,
  Select,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import TextArea from "antd/es/input/TextArea";

type Props = {
  openNotification: (placement: any, message: any, description: any) => void;
  fetchData: (page: number) => void;
  page: number;
  open: (inputOpen: boolean) => void;
  edit: boolean;
  id: number | null;
};

const strings = LocalizationStrings;

const TestdriveRequestForm: React.FC<Props> = (props: Props) => {
  const api = useAxios();
  const [data, setData] = React.useState<any>({});

  const [users, setUsers] = React.useState<any>([]);
  const [usersDropdown, setUsersDropdown] = React.useState<any>([]);

  const [reqState, setReqState] = React.useState<any>({});

  const [form] = Form.useForm();
  const formRef = React.useRef<any>();

  const onFinish = async (values: any) => {
    const req = {
      firstName: formRef.current.getFieldValue("firstName"),
      lastName: formRef.current.getFieldValue("lastName"),
      email: formRef.current.getFieldValue("email"),
      phone: formRef.current.getFieldValue("phoneNumber"),
      salutation: formRef.current.getFieldValue("salutation"),
      carModel: formRef.current.getFieldValue("carModel"),
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put(
        "/admin/testdrive-request/" + props.id,
        req
      );
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.request + strings.updatedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    } else {
      const response = await api.post("/admin/testdrive-request/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.request + strings.addedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          title: "",
          description: "",
          showroomCarId: "",
        });
      } else if (props.edit) {
        api.get("/admin/testdrive-request/" + props.id).then((res) => {
          //   console.log(res.data.data);
          formRef.current.setFieldsValue({
            firstName: res.data.data.firstName,
            lastName: res.data.data.lastName,
            email: res.data.data.email,
            phoneNumber: res.data.data.phone,
            salutation: res.data.data.salutation,
            carModel: res.data.data.carModel,
          });
        });
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Divider />
        <Form
          form={form}
          ref={formRef}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label={strings.salutation}
            name="salutation"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.salutation,
              },
            ]}
          >
            <Select>
              <Select.Option value="Mr">Mr</Select.Option>
              <Select.Option value="Mrs">Mrs</Select.Option>
              <Select.Option value="Ms">Ms</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={strings.firstName}
            name="firstName"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.firstName,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={strings.lastName}
            name="lastName"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.lastName,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={strings.email}
            name="email"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.email,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={strings.phoneNumber}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.phoneNumber,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={strings.car}
            name="carModel"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.car,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "90%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default TestdriveRequestForm;
