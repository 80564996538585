import React from "react";
import { Menu, Button } from "antd";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { currentLangState } from "./Dashboard";
import { useRecoilState } from "recoil";
import { Card } from "antd";
import { LocalizationStrings } from "../utils/localization";

let strings = LocalizationStrings;

function Navbar(color) {
  const navigate = useNavigate();
  const [currentLang, setCurrentLang] = useRecoilState(currentLangState);
  strings.setLanguage(currentLang);

  //create a menuItem const to store the menu items
  const menuItems = [
    {
      key: "1",
      //make the label a navlink to the path
      label: (
        <NavLink to="/dashboard/client-cars">
          <span className="label">{strings.clientCars}</span>
        </NavLink>
      ),
      path: "/dashboard/client-cars",
    },
    {
      key: "2",
      label: (
        <NavLink to="/dashboard/news">
          <span className="label">{strings.news}</span>
        </NavLink>
      ),
      path: "/dashboard/news",
    },
    {
      key: "3",
      label: (
        <NavLink to="/dashboard/events">
          <span className="label">{strings.events}</span>
        </NavLink>
      ),
      path: "/dashboard/events",
    },
    {
      key: "4",
      label: (
        <NavLink to="/dashboard/offers">
          <span className="label">{strings.offers}</span>
        </NavLink>
      ),
      path: "/dashboard/offers",
    },
    // {
    //   key: "5",
    //   label: (
    //     <NavLink to="/dashboard/vignettes">
    //       <span className="label">{strings.vignettes}</span>
    //     </NavLink>
    //   ),
    //   path: "/dashboard/vignettes",
    // },
    {
      key: "6",
      label: (
        <NavLink to="/dashboard/users">
          <span className="label">{strings.users}</span>
        </NavLink>
      ),
      path: "/dashboard/users",
    },
    {
      key: "7",
      label: (
        <NavLink to="/dashboard/showroom-cars">
          <span className="label">{strings.showroomCars}</span>
        </NavLink>
      ),
    },
    {
      key: "8",
      label: (
        <NavLink to="/dashboard/requests">
          <span className="label">{strings.requests}</span>
        </NavLink>
      ),
    },
    {
      key: "9",
      label: (
        <NavLink to="/dashboard/orders">
          <span className="label">{strings.orders}</span>
        </NavLink>
      ),
    },
    {
      key: "10",
      label: (
        <NavLink to="/dashboard/push-notifications">
          <span className="label">{strings.pushNotification}</span>
        </NavLink>
      ),
    },
    {
      key: "11",
      label: (
        <NavLink to="/dashboard/offer-requests">
          <span className="label">{strings.offerRequests}</span>
        </NavLink>
      ),
    },
    {
      key: "12",
      label: (
        <NavLink to="/dashboard/buyback-requests">
          <span className="label">{strings.buybackRequests}</span>
        </NavLink>
      ),
    },
    {
      key: "13",
      label: (
        <NavLink to="/dashboard/testdrive-requests">
          <span className="label">{strings.testdriveRequests}</span>
        </NavLink>
      ),
    },
    {
      key: "14",
      label: (
        <NavLink to="/dashboard/bmw-options">
          <span className="label">{`BMW ${strings.options.toLocaleLowerCase()}`}</span>
        </NavLink>
      ),
    },
      {
          key: "15",
          label: (
              <NavLink to="/dashboard/request-to-buy">
                  <span className="label">{strings.requestToBuyRequests}</span>
              </NavLink>
          ),
      },
      {
          key: "16",
          label: (
              <NavLink to="/dashboard/chat">
                  <span className="label">{strings.chats}</span>
              </NavLink>
          ),
      },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Menu
          theme="light"
          mode="inline"
          items={menuItems}
          style={{
            borderRadius: 15,
          }}
        >
          {/* <Menu.Item key="1">
          <NavLink to="/dashboard/client-cars">
            <span className="label">Client Cars</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/dashboard/news">
            <span className="label">News</span>
          </NavLink>
        </Menu.Item> */}
        </Menu>

        <Button
          style={{
            marginTop: 20,
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
          onClick={() => {
            localStorage.removeItem("bovaAccessToken");
            localStorage.removeItem("bovaRefreshToken");
            navigate("/signin");
          }}
        >
          <span className="label">{strings.logout}</span>
        </Button>
      </div>

      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        ></div>
      </div>
    </>
  );
}

export default Navbar;
