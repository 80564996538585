import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { atom, useRecoilState } from "recoil";
import Dashboard from "./Dashboard";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Image from "antd/lib/image";
import HTMLReactParser from "html-react-parser";
import "../assets/styles/news.css";

const OffersRTBDataState = atom({
  key: "OffersRTBDataState",
  default: null,
});

function ViewOffer() {
  const [newsData, setNewsData] = useRecoilState(OffersRTBDataState);
  let { id } = useParams();
  const api = useAxios();
  const navigate = useNavigate();

  const getNewsById = async (id) => {
    try {
      const response = await api.get(`/admin/offers/${id}`);
      console.log(response.data.data);
      setNewsData(response.data.data);
    } catch (error) {
      console.log(error);
      //   if (error.response.status === 404) {
      //     navigate("/404");
      //   }
    }
  };

  useEffect(() => {
    getNewsById(id);
    console.log(newsData);
  }, []);

  return (
    // display the news info here
    <div>
      {/* <div>Id: {newsData && newsData.id}</div>
      <div>Title: {newsData && newsData.title}</div> */}
      <div>
        {HTMLReactParser(
          typeof newsData?.textHtml == "string"
            ? newsData?.textHtml
            : "no content"
        )}
      </div>
      {/* 
      <p>Thumbnail: </p>
      <Image src={newsData?.thumbnail.url} /> */}
    </div>
    //   <div>
    //     <div
    //       className="article"
    //       sx={{
    //         backgroundImage: `url(${newsData?.thumbnail.url})`,
    //       }}
    //     >
    //       {/* <h1>{newsData && newsData.title}</h1> */}
    //       {HTMLReactParser(
    //         typeof newsData?.textHtml == "string"
    //           ? newsData?.textHtml
    //           : "no content"
    //       )}
    //     </div>
    //   </div>
  );
}

export default ViewOffer;
