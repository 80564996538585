import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { atom, useRecoilState } from "recoil";
import Dashboard from "./Dashboard";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Image from "antd/lib/image";

function ViewOrder() {
  const [data, setData] = useState(null);
  let { id } = useParams();
  const api = useAxios();
  const navigate = useNavigate();

  const getDataById = async (id) => {
    try {
      const response = await api.get(`/admin/orders/${id}`);
      console.log(response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
      //   if (error.response.status === 404) {
      //     navigate("/404");
      //   }
    }
  };

  useEffect(() => {
    getDataById(id);
    console.log(data);
  }, []);

  return (
    //display the car info here
    <div>
      <div>Id: {data && data?.id}</div>
      <div>Title: {data && data?.title}</div>
      <div>Status: {data && data?.status}</div>
      <div>
        <Image width={200} src={data && data?.thumbnail?.url}></Image>
      </div>
    </div>
  );
}

export default ViewOrder;
