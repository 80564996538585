import React, { useEffect } from "react";
import { useRecoilState, atom } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { IconButton } from "@mui/material";
import { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Modal,
  Space,
  Divider,
  Upload,
  Tooltip,
} from "antd";
import { Layout } from "antd";
import Item from "antd/es/list/Item";
import QuillTextBox from "./QuillTextBox.tsx";
import { Quill } from "react-quill";
import { currentLangState } from "./Dashboard";
import LocalizedStrings from "react-localization";
import dayjs from "dayjs";
import { LocalizationStrings } from "../utils/localization";
import { Typography, DatePicker } from "antd";
import moment from "moment";
import { quillObjState } from "./QuillTextBox.tsx";
import MapComponent from "./MapComponent";
import axios from "axios";

export const inputEventState = atom({
  key: "inputEventState",
  default: null,
});

export const editEventState = atom({
  key: "editEventState",
  default: null,
});

function Events(props) {
  let api = useAxios();

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useState(refreshTokenState);
  const [Srows, setRows] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [editData, setEditData] = useRecoilState(editEventState);
  const [inputData, setInputData] = useRecoilState(inputEventState);
  const [currentLang, setCurrentLang] = useRecoilState(currentLangState);
  //   const [usersDropdown, setUsersDropdown] = useState([]);

  const [inputOpen, setInputOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [quillText, setQuillText] = useRecoilState(quillObjState);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const stylesString = `/* Global styles */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Body styles */
  body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #eee;
    background-color: #222;
  }
  span {
    color: #eee !important;
  }
  strong {
    color: #eee !important;
  }
  i {
    color: #eee !important;
  }
  u {
    color: #eee !important;
  }
  q {
    color: #eee !important;
  }
  p {
    color: #eee !important;
  }
  
  /* Article container styles */
  .article {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #333;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  }
  
  /* Headline styles */
  .article h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
  }
  
  .article h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;
  }
  
  /* Paragraph styles */
  .article p {
    margin-bottom: 20px;
    text-align: justify;
    color: #ddd;
  }
  
  /* Image styles */
  .article img {
    display: block;
    max-width: 100%;
    margin: 20px auto;
  }
  
  /* Strong styles */
  .article strong {
    font-weight: bold;
    color: #fff;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 1200px) {
    /* Body styles */
    body {
      font-size: 14px;
    }
    
    /* Article container styles */
    .article {
      max-width: none;
      padding: 15px;

    }
  
    /* Headline styles */
    .article h1 {
      font-size: 28px;
      margin-bottom: 15px;
    }
  
    .article h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }
  
    /* Paragraph styles */
    .article p {
      margin-bottom: 15px;
    }
  
    /* Image styles */
    .article img {
      display: block;
    max-width: 100%;
    margin: 15px auto;
    }
  }
  `;

  const getStyledHtmlString = (html, styles, title) => {
    const htmlStyled = `<!DOCTYPE html>
    <html>
      <body>
      <style>
          ${styles}
        </style>
        <div class="article">
        ${html}
        </div>
      </body>
    </html>`;
    return htmlStyled.replace(/\n/g, "");
  };

  let strings = LocalizationStrings;
  strings.setLanguage(currentLang);

  const newsColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 75,
    },
    {
      title: strings.title,
      dataIndex: "title",
      key: "title",
      width: 200,
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record, index) => {
        if (text.length > 50) {
          return (
            <Tooltip title={<span>{text}</span>}>
              <span>{text.slice(0, 50)}...</span>
            </Tooltip>
          );
        } else {
          return <span>{text}</span>;
        }
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100,
    // },
    {
      title: strings.createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
    },

    {
      title: strings.options,
      key: "options",
      dataIndex: "options",
      width: 150,
      render: (text, record, index) => (
        <>
          <IconButton
            key="edit"
            type="primary"
            className="tag-primary"
            onClick={() => {
              //console log this row
              setEditData(record);
              handleUpdateDialogClickOpen(record.id);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            key="delete"
            type="primary"
            className="tag-primary"
            onClick={() => {
              setDeleteId(record.id);
              handleDeleteButtonClickRow(record);
            }}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton
            key="info"
            type="primary"
            className="tag-primary"
            onClick={() => {
              navigate(`/events/${record.id}`);
            }}
          >
            <InfoCircleOutlined />
          </IconButton>
        </>
      ),
    },
  ];

  let isMounted = true;

  const fetchData = async (p, isSearchEmpty = false) => {
    try {
      if (isMounted) {
        // console.log(headerConfig);
        const response = await api.get(`/admin/events`, {
          params: {
            page: p,
            limit: 10,
            search: isSearchEmpty ? null : search,
            sortBy: "createdAt:DESC",
          },
        });
        console.log(response);
        setTotal(response.data.data.meta.totalItems);
        var data = new Array();
        for (let i = 0; i < response.data.data.data.length; i++) {
          data.push(response.data.data.data[i]);
          //add key element to each row
          data[i].key = i;
          data[i].createdAt = dayjs(data[i].createdAt).format(
            "DD/MM/YYYY HH:mm"
          );
          // console.log("data: " + i + " " + data);
          // setRows([...Srows, ...data]);
        }
        setRows(data);
        // console.log(Srows);

        // console.log("rows1 \n" + rows1);
      }
    } catch (error) {
      console.error(error);
      // props.logOut();
    }
  };

  useEffect(() => {
    const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
    // console.log(user.role);
    if (user.role !== "admin") {
      alert("not admin");
      props.logOut();
      return;
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    setRefreshToken(localStorage.getItem("bovaRefreshToken"));
    // console.log(accessToken);
    if (
      (localStorage.getItem("bovaAccessToken") === "" &&
        localStorage.getItem("bovaRefreshToken") === "") ||
      (localStorage.getItem("bovaAccessToken") === null &&
        localStorage.getItem("bovaRefreshToken") === null)
    ) {
      navigate("/signin");
    }

    fetchData(page);
    return () => (isMounted = false);
  }, [props.baseUrl]);

  //   const getUsers = async () => {
  //     const response = await api.get("/admin/users");
  //     // console.log(response);
  //     return response.data.data.data;
  //   };

  //   const getUserEmails = () => {
  //     let data = [];
  //     for (let i = 0; i < usersDropdown.length; i++) {
  //       data.push(usersDropdown[i].email);
  //     }
  //     return data;
  //   };

  const getDataById = async (id) => {
    const response = await api.get(`/admin/events/${id}`);
    // console.log(response);
    console.log(response.data.data);
    // setEditData(response.data.data);

    return response.data.data;
  };

  const handleUpdateDialogClickOpen = async (id) => {
    // console.log(id);
    // let data = await getDataById(id);
    // console.log(data);
    // setEditData(null);
    // setEditData(data);
    setQuillText(null);
    const data = await getDataById(id);
    setEditData(data);
    setQuillText(data?.textHtml);

    setUpdateOpen(true);
  };

  const handleInputDialogClose = () => {
    setInputData({});
    setInputOpen(false);
  };

  const handleUpdateDialogClose = () => {
    setEditData({});
    setUpdateOpen(false);
  };

  const handleInputDialogSubmit = async (event) => {
    if (
      inputData?.title === "" ||
      inputData?.textDelta === "" ||
      inputData?.destinationName === "" ||
      inputData?.date === "" ||
      inputData?.thumbnail === null ||
      inputData?.thumbnail === undefined ||
      inputData?.title === undefined ||
      inputData?.textDelta === undefined ||
      inputData?.destinationName === undefined ||
      inputData?.date === undefined
    ) {
      const req = {
        title: inputData?.title,
        // description: inputData?.description,
        // image: inputData?.image,
        textDelta: JSON.stringify(inputData?.textDelta),
        textHtml: getStyledHtmlString(
          inputData?.textHtml,
          stylesString,
          inputData?.title
        ),
        thumbnail: {
          url: inputData?.thumbnail.url,
          key: inputData?.thumbnail.key,
        },
        destinationName: inputData?.destinationName,
        destinationLatitude: inputData?.destinationLatitude,
        destinationLongitude: inputData?.destinationLongitude,
        date: inputData?.date,
      };
      console.log(req);
      alert("all fields are required");
      return;
    }
    setInputOpen(false);
    console.log(inputData);
    const req = {
      title: inputData?.title,
      // description: inputData?.description,
      // image: inputData?.image,
      textDelta: JSON.stringify(inputData?.textDelta),
      textHtml: getStyledHtmlString(
        inputData?.textHtml,
        stylesString,
        inputData?.title
      ),
      thumbnail: {
        url: inputData?.thumbnail.url,
        key: inputData?.thumbnail.key,
      },
      destinationName: inputData?.destinationName,
      destinationLatitude: inputData?.destinationLatitude,
      destinationLongitude: inputData?.destinationLongitude,
      date: inputData?.date,
    };
    console.log(req);
    const response = await api.post("/admin/events/", req);
    // console.log(response);
    setInputData({});
    if (response.status === 201) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.event + " " + strings.createdSuccessfullyN
      );
    }
    fetchData(page);
  };

  const handleUpdateSubmitDialog = async (event) => {
    if (
      editData?.title === "" ||
      editData?.textDelta === "" ||
      editData?.destinationName === "" ||
      editData?.date === "" ||
      editData?.thumbnail === null ||
      editData?.thumbnail === undefined ||
      editData?.title === undefined ||
      editData?.textDelta === undefined ||
      editData?.destinationName === undefined ||
      editData?.date === undefined
    ) {
      alert("all fields are required");
      return;
    }
    setUpdateOpen(false);
    console.log(editData);
    const req = {
      title: editData?.title,
      // description: inputData?.description,
      // image: inputData?.image,
      textDelta: JSON.stringify(editData?.textDelta),
      textHtml: getStyledHtmlString(
        editData?.textHtml,
        stylesString,
        editData?.title
      ),
      thumbnail: editData?.thumbnail,
      destinationName: editData?.destinationName,
      destinationLatitude: editData?.destinationLatitude,
      destinationLongitude: editData?.destinationLongitude,
      date: editData?.date,
    };
    console.log(req);
    const response = await api.put(`/admin/events/${editData.id}`, req);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.event + " " + strings.updatedSuccessfullyN
      );
    }

    console.log(response);
    setEditData({});
    fetchData(page);
  };

  const handleDeleteButtonClickRow = (cellValues) => {
    // console.log(event);
    setDeleteId(cellValues.id);
    console.log("Delete Row " + selectedRow);
    setDeleteOpen(true);
  };

  const handleDeleteDialogCancel = () => {
    setDeleteOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    setDeleteOpen(false);
    const response = await api.delete(`/admin/events/${deleteId}`);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.event + " " + strings.deletedSuccessfullyN
      );
    }
    console.log(response);
    fetchData(page);
  };

  const imageHandler = async (e, o) => {
    const file = e.file;
    if (file) {
      const fileUploadUrl = "admin/file-upload/upload-signed-url";

      var fileName = file.name;

      //make a get request with the file name and type
      const res = await api.get(fileUploadUrl, {
        params: {
          filename: fileName,
          mediaType: file.type,
        },
      });
      console.log(res.data);
      const url = res.data.data.url;
      const newFileName = res.data.data.filename;
      const res2 = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      console.log(res2);

      const res3 = await api.get("admin/file-upload/public-url?", {
        params: {
          filename: newFileName,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (res3.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.imageUploadedSuccessfully
        );
      }
      // console.log(res3);
      var fetchImageUrl = res3.data.data.url;
      var fetchImageKey = res.data.data.filename;
      console.log(fetchImageUrl);
      if (o === "input") {
        console.log("ajfjafaljfhalfj");
        setInputData({
          ...inputData,
          thumbnail: {
            key: fetchImageKey,
            url: fetchImageUrl,
          },
        });
      } else if (o === "edit") {
        setEditData({
          ...editData,
          thumbnail: {
            key: fetchImageKey,
            url: fetchImageUrl,
          },
        });
      }
    }
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleRemove = (file) => {
    console.log(file);
  };

  return (
    <div
      style={{
        width: 1000,
      }}
    >
      <div>
        <Layout
          style={{
            minHeight: "100vh",
            //set a width to the layout
          }}
        >
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title={strings.events}
                  extra={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        placeholder={strings.search}
                        prefix={<SearchOutlined />}
                        size="small"
                        style={{
                          marginRight: 25,
                        }}
                        onPressEnter={(event) => {
                          setSearch(event.target.value);
                          fetchData(page);
                        }}
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                          if (event.target.value === "" || null) {
                            setSearch(null);
                            fetchData(page, true);
                          }
                        }}
                        value={search}
                      />
                      <Button
                        onClick={() => {
                          setInputOpen(true);
                          setQuillText(null);
                        }}
                      >
                        {strings.create}
                      </Button>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={newsColumns}
                      dataSource={Srows}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                        current: page,
                        //set number of pages
                        total: total,
                      }}
                      onChange={(pagination) => {
                        setPage(pagination.current);
                        console.log(pagination.current);
                        fetchData(pagination.current);
                      }}
                      size="large"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>
      {/* <Container fixed>
        <div style={{ height: 540, width: "450%" }}>
          <DataGrid
            getRowId={(row) => row.id}
            rows={Srows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            disableSelectionOnClick
          />
        </div>
      </Container> */}
      {/* <Container fixed>
        <Button
          sx={{ mt: 2 }}
          onClick={() => {
            setInputOpen(true);
          }}
        >
          Create events
        </Button>
      </Container> */}
      {/* //Modal Input */}
      <Modal
        title={strings.create}
        open={inputOpen}
        onOk={handleInputDialogSubmit}
        onCancel={handleInputDialogClose}
        inputData={inputData}
        width={1000}
      >
        <Divider />
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Typography>
            {strings.title}
            <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.title}
              id="title"
              size="small"
              required
              name="title"
              value={inputData?.title}
              onChange={(e) => {
                setInputData({ ...inputData, title: e.target.value });
              }}
            />
          </Typography>

          {/* <Input
            margin="dense"
            placeholder="Description"
            id="description"
            size="small"
            required
            name="description"
            value={inputData?.description}
            onChange={(e) => {
              setInputData({ ...inputData, description: e.target.value });
            }}
          /> */}
          <Typography>
            {strings.content}
            <br />
            <QuillTextBox
              stateSet={setInputData}
              currentState={inputData}
              actionType="inputEvent"
            />
          </Typography>
          <Typography>
            Thumbnail: {"    "}
            <br />
            {/* <input
              type="file"
              name="image"
              onChange={(e) => {
                imageHandler(e, "input");
              }}
            /> */}
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(file) => {
                imageHandler(file, "input");
              }}
              beforeRequest={false}
              onRemove={(file) => {
                handleRemove(file);
              }}
              // onChange={imageHandler}
            >
              {inputData?.thumbnail ? (
                <img
                  src={inputData?.thumbnail?.url}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Typography>
          <Typography>
            {strings.destinationName}
            <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.destinationName}
              id="destinationName"
              size="small"
              required
              name="destinationName"
              value={inputData?.destinationName}
              onChange={(e) => {
                setInputData({ ...inputData, destinationName: e.target.value });
              }}
            />
          </Typography>
          <Typography>
            {strings.destinationLongitude + " (" + strings.notRequired + ")"}
            <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.destinationLongitude}
              id="destinationLongitude"
              size="small"
              required
              name="destinationLongitude"
              value={inputData?.destinationLongitude}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  destinationLongitude: e.target.value,
                });
              }}
            />
          </Typography>
          <Typography>
            {strings.destinationLatitude + " (" + strings.notRequired + ")"}
            <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.destinationLatitude}
              id="destinationLatitude"
              size="small"
              required
              name="destinationLatitude"
              value={inputData?.destinationLatitude}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  destinationLatitude: e.target.value,
                });
              }}
            />
          </Typography>

          <Typography>
            {strings.date}
            <br />
            <DatePicker
              onChange={(date, dateString) => {
                setInputData({
                  ...inputData,
                  date: dateString,
                });
              }}
            />
          </Typography>
          {/* <MapComponent /> */}
        </Space>
      </Modal>
      {/* //Edit Input */}
      <Modal
        title={strings.edit}
        open={updateOpen}
        onOk={handleUpdateSubmitDialog}
        onCancel={handleUpdateDialogClose}
        width={1000}
      >
        <Divider />
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Typography>
            {strings.title}
            <br />
            <Input
              margin="dense"
              placeholder={strings.title}
              id="title"
              size="small"
              required
              name="title"
              value={editData?.title}
              style={{
                color: "black",
              }}
              onChange={(e) => {
                setEditData({ ...editData, title: e.target.value });
              }}
            />
          </Typography>
          {/* <Input
            fullWidth
            multiline
            margin="dense"
            placeholder="Description"
            id="description"
            size="small"
            required
            name="description"
            value={editData?.description}
            onChange={(e) => {
              setEditData({ ...editData, description: e.target.value });
            }}
          /> */}
          <Typography>
            {strings.content}
            <br />
            <QuillTextBox
              stateSet={setEditData}
              currentState={editData}
              value={editData?.textDelta}
              actionType="editEvent"
            />
          </Typography>
          <Typography>
            Thumbnail: {"    "}
            <br />
            {/* <input
              type="file"
              name="image"
              onChange={(e) => {
                imageHandler(e, "edit");
              }}
            /> */}
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(file) => {
                imageHandler(file, "edit");
              }}
              beforeRequest={false}
              onRemove={(file) => {
                handleRemove(file);
              }}
              // onChange={imageHandler}
            >
              {editData?.thumbnail ? (
                <img
                  src={editData?.thumbnail?.url}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Typography>

          <Typography>
            {strings.destinationName}
            <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.destinationName}
              id="destinationName"
              size="small"
              required
              name="destinationName"
              value={editData?.destinationName}
              onChange={(e) => {
                setEditData({ ...editData, destinationName: e.target.value });
              }}
            />
          </Typography>
          <Typography>
            {strings.destinationLongitude + " (" + strings.notRequired + ")"}
            <br />
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.destinationLongitude}
              id="destinationLongitude"
              size="small"
              required
              name="destinationLongitude"
              value={editData?.destinationLongitude}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  destinationLongitude: e.target.value,
                });
              }}
            />
          </Typography>
          <Typography>
            {strings.destinationLatitude + " (" + strings.notRequired + ")"}
            <Input
              style={{
                color: "black",
              }}
              margin="dense"
              placeholder={strings.destinationLatitude}
              id="destinationLatitude"
              size="small"
              required
              name="destinationLatitude"
              value={editData?.destinationLatitude}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  destinationLatitude: e.target.value,
                });
              }}
            />
          </Typography>

          <Typography>
            {strings.date}
            <br />
            <DatePicker
              defaultValue={editData?.date ? dayjs(editData?.date) : ""}
              value={editData?.date ? dayjs(editData?.date) : ""}
              onChange={(date, dateString) => {
                setEditData({
                  ...editData,
                  date: dateString,
                });
              }}
            />
          </Typography>
        </Space>
      </Modal>

      <Modal
        title={strings.deleteConfirmation}
        open={deleteOpen}
        onOk={handleDeleteDialogConfirm}
        onCancel={handleDeleteDialogCancel}
      ></Modal>
    </div>
  );
}

export default Events;
