import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useState } from "react";

const containerStyle = {
  width: "800px",
  height: "350px",
};

const libraries = ["places"];

function MapComponent() {
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });

  const api_key = "AIzaSyB84AOHVWTw6nGSgpjr_gIq47N3WTzcPXo";

  let searchBox = null;

  const onSearchLoad = (ref) => (searchBox = ref);
  const onPlacesChanged = () => {
    console.log(searchBox?.getPlaces()[0].geometry.location.lat());
    console.log(searchBox?.getPlaces()[0].geometry.location.lng());
    setCenter({
      lat: searchBox?.getPlaces()[0].geometry.location.lat(),
      lng: searchBox?.getPlaces()[0].geometry.location.lng(),
    });
  };

  const onMarkerLoad = (marker) => {
    console.log("marker: ", marker);
  };

  return (
    <div>
      <LoadScript googleMapsApiKey={api_key} libraries={libraries}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
          <Marker onLoad={onMarkerLoad} position={center} />
          <StandaloneSearchBox
            onLoad={onSearchLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Customized your placeholder"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
              }}
            />
          </StandaloneSearchBox>
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default React.memo(MapComponent);
