import React from "react";
import {
  Form,
  Input,
  Button,
  Space,
  AutoComplete,
  Divider,
  Upload,
  Select,
  DatePicker,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";

const strings = LocalizationStrings;

const OrdersInputForm = (props) => {
  const api = useAxios();
  const [data, setData] = React.useState({});

  const [users, setUsers] = React.useState([]);
  const [usersDropdown, setUsersDropdown] = React.useState([]);

  const [form] = Form.useForm();

  const getUsers = async (filter) => {
    if (filter) {
      const response = await api.get("/admin/users", {
        params: {
          search: filter,
        },
      });
      return response.data.data.data;
    } else {
      const response = await api.get("/admin/users");
      // console.log(response);
      return response?.data.data.data;
    }
  };

  const searchUsers = (event) => {
    // console.log(event);
    getUsers(event).then((res) => {
      setUsers(res);
      if (event.length > 0) {
        // console.log("res: " + res);
        const filteredUsers = users.filter((user) => {
          return user?.email.toLowerCase().includes(event.toLowerCase());
        });
        // console.log(filteredUsers);
        setUsersDropdown(filteredUsers);
      } else {
        // console.log("res: " + res);
        setUsersDropdown(users);
      }
    });
  };

  const onFinish = async (values) => {
    const req = {
      title: data?.title,
      userId: data?.userId,
      orderedAt: data?.orderedAt,
      status: data?.status,
      infoDate: data?.infoDate,
      thumbnail: data?.thumbnail
        ? {
            url: data?.thumbnail?.url,
            key: data?.thumbnail?.key,
          }
        : null,
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/orders/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.order + strings.updatedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    } else {
      const response = await api.post("/admin/orders/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.order + strings.addedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      setData({});
      if (!props.edit) {
        form.setFieldsValue({
          title: "",
          userId: "",
          orderedAt: "",
          status: "",
          infoDate: "",
          thumbnail: null,
        });
      } else if (props.edit) {
        api.get("/admin/orders/" + props.id).then((res) => {
          //   console.log(res);
          setData({ ...res.data.data, userId: res.data.data.user.id });
          //   console.log(res.data.data);
          form.setFieldsValue({
            title: res.data.data.title,
            user: res.data.data.user.email,
            orderedAt: dayjs(res.data.data.orderedAt),
            status: res.data.data.status,
            infoDate: res.data.data.infoDate
              ? dayjs(res.data.data.infoDate)
              : null,
          });
        });
      }
      getUsers(null).then((res) => {
        setUsers(res);
        setUsersDropdown(res);
      });
    }
  };

  const imageHandler = async (e, o) => {
    const file = e.file;
    if (file) {
      const fileUploadUrl = "admin/file-upload/upload-signed-url";

      var fileName = file.name;

      //make a get request with the file name and type
      const res = await api.get(fileUploadUrl, {
        params: {
          filename: fileName,
          mediaType: file.type,
        },
      });
      console.log(res.data);
      const url = res.data.data.url;
      const newFileName = res.data.data.filename;
      const res2 = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read",
        },
      });
      console.log(res2);

      const res3 = await api.get("admin/file-upload/public-url?", {
        params: {
          filename: newFileName,
        },
      });
      // console.log(res3);
      if (res3.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.imageUploadedSuccessfully
        );
      }
      var fetchImageUrl = res3.data.data.url;
      var fetchImageKey = res.data.data.filename;
      console.log(fetchImageUrl);
      setData({
        ...data,
        thumbnail: {
          key: fetchImageKey,
          url: fetchImageUrl,
        },
      });
    }
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleRemove = (file) => {
    console.log(file);
  };

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Divider />
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={strings.title}
            name="title"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.title.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <Input
              placeholder={strings.title}
              value={data?.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label={strings.status}
            name="status"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.status.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
                color: "black",
              }}
              options={[
                { value: "047", label: "Колата е поръчана" },
                { value: "111", label: "Колата е предвидена за производство" },
                {
                  value: "150",
                  label: "Колата ще бъде произведена на … шаси:",
                },
                {
                  value: "165",
                  label: "Колата се транспортира от Америка - Европа",
                },
                {
                  value: "182",
                  label: "Колата се транспортира към склад Австрия",
                },
                { value: "193", label: "Колата е доставена в склад Австрия" },
                {
                  value: "195",
                  label: "Колата се транспортира от склад Австрия към Б-я",
                },
                { value: "198", label: "Колата е доставена в Бова Кар" },
              ]}
              onSelect={(value, record) => {
                setData({
                  ...data,
                  status: value,
                  statusDescription: record.label,
                });
              }}
              value={data?.statusDescription}
              placeholder={strings.status}
            />
          </Form.Item>
          <Form.Item
            label={strings.infoDate}
            name="infoDate"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
                color: "black",
              }}
              onChange={(date) => {
                setData({
                  ...data,
                  infoDate: date,
                });
              }}
              value={data?.infoDate ? dayjs(data?.infoDate) : null}
              placeholder={strings.infoDate}
            />
          </Form.Item>
          <Form.Item
            label={strings.user}
            name="user"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.user.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <AutoComplete
              value={data?.user}
              options={
                //get only the emails from the usersdropdown state}
                usersDropdown?.map((user) => {
                  return { value: user?.email };
                })
              }
              style={{ width: "100%" }}
              onSearch={(e) => searchUsers(e)}
              onSelect={(e) => {
                console.log(e);
                for (let i = 0; i < usersDropdown.length; i++) {
                  if (usersDropdown[i]?.email === e) {
                    setData({
                      ...data,
                      userId: usersDropdown[i]?.id,
                      user: e,
                    });
                    console.log("match");
                  }
                }
              }}
              // onSearch={onSearch}
              placeholder={strings.user}
            />
          </Form.Item>
          <Form.Item
            label={strings.orderedAt}
            name="orderedAt"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.orderedAt.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <DatePicker
              style={{
                width: "100%",
                color: "black",
              }}
              onChange={(date) => {
                setData({
                  ...data,
                  orderedAt: date,
                });
              }}
              value={data?.orderedAt ? dayjs(data?.orderedAt) : null}
              placeholder={strings.orderedAt}
            />
          </Form.Item>
          <Form.Item
            label={strings.image}
            name="image"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(file) => {
                imageHandler(file, "input");
              }}
              onRemove={(file) => {
                handleRemove(file);
              }}
              // onChange={imageHandler}
            >
              {data?.thumbnail ? (
                <div>
                  <img
                    src={data?.thumbnail?.url}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                  <DeleteOutlined
                    style={{
                      position: "absolute",
                      top: 10,
                      left: 110,
                      zIndex: 1,
                      borderRadius: 10,
                      padding: 5,
                    }}
                    onClick={() => {
                      setData({ ...data, thumbnail: null });
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div>{uploadButton} </div>
                  <div>
                    <DeleteOutlined
                      style={{
                        position: "absolute",
                        top: 10,
                        left: 110,
                        zIndex: 1,
                        borderRadius: 10,
                        padding: 5,
                      }}
                      onClick={() => {
                        setData({ ...data, thumbnail: null });
                      }}
                    />
                  </div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "90%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default OrdersInputForm;
