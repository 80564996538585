import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { Typography, Grid, Paper, Button, ButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import {LocalizationStrings} from "../utils/localization";


function ViewUser() {
    const [data, setData] = useState(null);
    const { id } = useParams();
    const api = useAxios();
    const navigate = useNavigate();
    const strings = LocalizationStrings;


    const getDataById = async (id) => {
        try {
            const response = await api.get(`/admin/users/${id}`);
            console.log(response.data.data);
            setData(response.data.data);
        } catch (error) {
            console.log(error);
            // Uncomment if you want to navigate to a 404 page on error
            // if (error.response.status === 404) {
            //   navigate("/404");
            // }
        }
    };

    useEffect(() => {
        getDataById(id);
    }, [id]);

    return (
        <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h4" gutterBottom>
                {strings.Details}
            </Typography>
            {data ? (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Id:</strong> {data.id}</Typography>
                        <Typography variant="body1"><strong>{strings.firstName}:</strong> {data.firstName}</Typography>
                        <Typography variant="body1"><strong>{strings.lastName}:</strong> {data.lastName}</Typography>
                        <Typography variant="body1"><strong>{strings.phone}:</strong> {data.phoneNumber}</Typography>
                        <Typography variant="body1"><strong>{strings.email}:</strong> {data.email}</Typography>
                        <Typography variant="body1"><strong>{strings.role}:</strong> {data.role}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>{strings.birthDate}:</strong> {dayjs(data.birthDate).format(
                            "DD/MM/YYYY"
                        )}</Typography>
                        <Typography variant="body1"><strong>{strings.isEmailVerified}:</strong> {data.isEmailVerified ? "Yes" : "No"}</Typography>
                        <Typography variant="body1"><strong>{strings.driverLicenseNumber}:</strong> {data.driverLicenseNumber}</Typography>
                        <Typography variant="body1"><strong>{strings.personalIdNumber}:</strong> {data.personalIdNumber}</Typography>
                        <Typography variant="body1"><strong>{strings.createdAt}:</strong> {dayjs(data.createdAt).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}</Typography>
                        <Typography variant="body1"><strong>{strings.updatedAt}:</strong> {dayjs(data.updatedAt).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}</Typography>

                    </Grid>
                </Grid>
            ) : (
                <Typography variant="body1">Loading...</Typography>
            )}
            <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="request buttons"
                style={{ marginTop: "20px", gap: "8px" }} // Adjust gap as needed
            >
                <Button onClick={() => navigate(`/admin/requests/email/${data.email}`)}>{strings.requests}</Button>
                <Button onClick={() => navigate(`/admin/orders/email/${data.email}`)}>{strings.orders}</Button>
                <Button onClick={() => navigate(`/admin/buyback-requests/email/${data.email}`)}>{strings.buybackRequests}</Button>
                <Button onClick={() => navigate(`/admin/testdrive-requests/email/${data.email}`)}>{strings.testdriveRequests}</Button>
                <Button onClick={() => navigate(`/request-to-buy/email/${data.email}`)}>{strings.requestToBuyRequests}</Button>
                <Button onClick={() => navigate(`/admin/offer-requests/email/${data.email}`)}>{strings.offerRequests}</Button>
            </ButtonGroup>

        </Paper>
    );
}

export default ViewUser;
