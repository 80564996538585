import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { atom, useRecoilState } from "recoil";
import Dashboard from "./Dashboard";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { accessTokenState, refreshTokenState } from "../App";
import { Card, Divider, Space } from "antd";
import dayjs from "dayjs";
import { LocalizationStrings } from "../utils/localization";

const carDataState = atom({
  key: "carDataState",
  default: null,
});

function ViewClientCar() {
  const [carData, setCarData] = useRecoilState(carDataState);
  let { id } = useParams();
  const api = useAxios();
  const navigate = useNavigate();

  const strings = LocalizationStrings;

  const styles = {
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    ".article": {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "30px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      lineHeight: 1.5,
    },
    ".article h1": {
      fontSize: "36px",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
    },
    ".article h2": {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    ".article p": {
      marginBottom: "20px",
      textAlign: "justify",
    },
    ".article img": {
      display: "block",
      maxWidth: "100%",
      margin: "20px auto",
    },
    ".article strong": {
      fontWeight: "bold",
    },
  };

  const getCarById = async (id) => {
    try {
      const response = await api.get(`/admin/client-cars/${id}`);
      console.log(response.data.data);
      setCarData(response.data.data);
    } catch (error) {
      console.log(error);
      //   if (error.response.status === 404) {
      //     navigate("/404");
      //   }
    }
  };

  useEffect(() => {
    getCarById(id);
    console.log(carData);
  }, []);

  return (
    //display the car info here
    <div
      style={{
        textColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card title="Car Info" bordered={false} style={{ width: 500 }}>
        <Space direction="vertical">
          <Typography>ID: {carData && carData.id}</Typography>
          <Typography>
            {strings.brand}: {carData && carData.brand}
          </Typography>
          <Typography>
            {strings.model}: {carData && carData.model}
          </Typography>
          <Typography>
            {strings.registrationNumber}:{" "}
            {carData && carData.registration_number}
          </Typography>
          <Typography>VIN: {carData && carData.vin}</Typography>
          <Typography>
            {strings.createdAt}:{" "}
            {carData && dayjs(carData.createdAt).format("DD/MM/YYYY hh:mm")}
          </Typography>
          <Typography>
            {strings.updatedAt}:{" "}
            {carData && dayjs(carData.updatedAt).format("DD/MM/YYYY hh:mm")}
          </Typography>
          <Typography>
            {strings.user}: {carData && carData.user.email}
          </Typography>
          <Divider />
          <Typography>
            {strings.vignettes}:
            <Divider />
            {carData &&
              carData.vignettes.map((vignette) => (
                <div key={vignette.id}>
                  <div>ID: {vignette.id}</div>
                  <div>
                    {strings.startsAt}:{" "}
                    {dayjs(vignette.startsAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <div>
                    {strings.endsAt}:{" "}
                    {dayjs(vignette.endsAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <div>
                    {strings.createdAt}:{" "}
                    {dayjs(vignette.createdAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <div>
                    {strings.updatedAt}:{" "}
                    {dayjs(vignette.updatedAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <Divider dashed />
                </div>
              ))}
          </Typography>
          <Typography>
            {strings.insurances}:
            <Divider />
            {carData &&
              carData.insurances.map((insurance) => (
                <div key={insurance.id}>
                  <div>ID: {insurance.id}</div>
                  <div>
                    {strings.startsAt}:{" "}
                    {dayjs(insurance.startsAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <div>
                    {strings.price}: {insurance.price}
                  </div>
                  <div>
                    {strings.createdAt}:{" "}
                    {dayjs(insurance.createdAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <div>
                    {strings.updatedAt}:{" "}
                    {dayjs(insurance.updatedAt).format("DD/MM/YYYY hh:mm")}
                  </div>
                  <Divider dashed />
                </div>
              ))}
          </Typography>
        </Space>
      </Card>
    </div>
  );
}

export default ViewClientCar;
