import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { Card } from "antd";
import { Image } from "antd";

function ViewShowroomCar() {
    const [data, setData] = useState(null);
    let { id } = useParams();
    const api = useAxios();
    const navigate = useNavigate();

    const getDataById = async (id) => {
        try {
            const response = await api.get(`/admin/showroom-cars/${id}`);
            console.log(response.data.data);
            setData(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getDataById(id);
        console.log(data);
    }, [id]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "50px",
            }}
        >
            <Card
                style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                }}
                bordered
            >
                <div>
                    <div>Id: {data && data?.id}</div>
                    <div>Title: {data && data?.title}</div>
                    <div>Description: {data && data?.description}</div>
                    <div>Category: {data && data?.category}</div>
                    <div>Engine Displacement: {data && data?.engineDisplacement}</div>
                    <div>Engine Power: {data && data?.enginePower}</div>
                    <div>Engine Type: {data && data?.engineType}</div>
                    <div>Transmission Type: {data && data?.transmissionType}</div>
                    <div>Price: {data && data?.price}</div>
                    <div>Production Year: {data && data?.productionYear}</div>
                    <div>Mileage: {data && data?.mileage}</div>
                    <div>Created At: {data && data?.createdAt}</div>
                    <div>Updated At: {data && data?.updatedAt}</div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div>Images:</div>
                        {data &&
                            data?.images?.map((image) => (
                                <Image
                                    key={image?.id}
                                    src={image?.url}
                                    alt="car image"
                                    width={800}
                                    style={{ marginBottom: "20px" }}
                                />
                            ))}

                        <div>Videos:</div>
                        {data &&
                            data?.video?.map((video) => (
                                <video
                                    key={video?.id}
                                    width="800"
                                    height="450"
                                    controls
                                    style={{ marginBottom: "20px" }}
                                >
                                    <source src={video?.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ))}
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default ViewShowroomCar;
