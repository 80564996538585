import React, { useEffect } from "react";
import { useRecoilState, atom } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { IconButton, Typography } from "@mui/material";
import { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Modal,
  Space,
  Divider,
} from "antd";
import { Layout } from "antd";
import { DatePicker } from "antd";

import dayjs from "dayjs";
import LocalizedStrings from "react-localization";

import { currentLangState } from "./Dashboard";
import { LocalizationStrings } from "../utils/localization";
import { AutoComplete } from "antd";
import moment from "moment";
import UsersInputForm from "./forms/UsersInputForm";

function Users(props) {
  let api = useAxios();

  const strings = LocalizationStrings;

  const [currentLang, setCurrentLang] = useRecoilState(currentLangState); // Default language is English (en

  strings.setLanguage(currentLang);

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [Srows, setRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const [inputData, setInputData] = useState(null);
  //   const [usersDropdown, setUsersDropdown] = useState([]);

  const [inputOpen, setInputOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const newsColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 75,
    },
    {
      title: strings.firstName,
      dataIndex: "firstName",
      key: "firstName",
      width: 200,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: strings.lastName,
      dataIndex: "lastName",
      key: "lastName",
      width: 200,
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: strings.email,
      dataIndex: "email",
      key: "email",
      width: 200,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: strings.role,
      dataIndex: "role",
      key: "role",
    },
    {
      title: strings.createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
    },

    {
      title: strings.options,
      key: "options",
      dataIndex: "options",
      width: 150,
      render: (text, record, index) => (
        <>
          <IconButton
            key="edit"
            type="primary"
            className="tag-primary"
            onClick={() => {
              //console log this row
              setEditData(record);
              handleUpdateDialogClickOpen(record.id);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            key="delete"
            type="primary"
            className="tag-primary"
            onClick={() => {
              setDeleteId(record.id);
              handleDeleteButtonClickRow(record);
            }}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton
            key="info"
            type="primary"
            className="tag-primary"
            onClick={() => {
              navigate(`/users/${record.id}`);
            }}
          >
            <InfoCircleOutlined />
          </IconButton>
        </>
      ),
    },
  ];

  let isMounted = true;

  const fetchData = async (p, isSearchEmpty = false) => {
    try {
      if (isMounted) {
        // console.log(headerConfig);
        const response = await api.get(`/admin/users`, {
          params: {
            page: p,
            limit: 10,
            search: isSearchEmpty ? null : search,
            sortBy: "createdAt:DESC",
          },
        });
        console.log(response);
        setTotal(response.data.data.meta.totalItems);
        var data = new Array();
        for (let i = 0; i < response.data.data.data.length; i++) {
          data.push(response.data.data.data[i]);
          //add key element to each row
          data[i].key = i;
          data[i].createdAt = dayjs(data[i].createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          // console.log("data: " + i + " " + data);
          // setRows([...Srows, ...data]);
        }
        setRows(data);
        // console.log(Srows);

        // console.log("rows1 \n" + rows1);
      }
    } catch (error) {
      console.error(error);
      // props.logOut();
    }
  };

  useEffect(() => {
    const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
    // console.log(user.role);
    if (user.role !== "admin") {
      alert("not admin");
      props.logOut();
      return;
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    setRefreshToken(localStorage.getItem("bovaRefreshToken"));
    // console.log(accessToken);
    if (
      (localStorage.getItem("bovaAccessToken") === "" &&
        localStorage.getItem("bovaRefreshToken") === "") ||
      (localStorage.getItem("bovaAccessToken") === null &&
        localStorage.getItem("bovaRefreshToken") === null)
    ) {
      navigate("/signin");
    }

    fetchData(page);
    return () => (isMounted = false);
  }, [props.baseUrl]);

  const getDataById = async (id) => {
    const response = await api.get(`/admin/users/${id}`);
    // console.log(response);

    return response.data.data;
  };

  const handleUpdateDialogClickOpen = async (id) => {
    // console.log(id);
    let data = await getDataById(id);
    console.log(data);
    setEditData(data);
    setUpdateOpen(true);
  };

  const handleInputDialogClose = () => {
    setInputData({});
    setInputOpen(false);
  };

  const handleUpdateDialogClose = () => {
    setEditData({});
    setUpdateOpen(false);
  };

  const handleDeleteButtonClickRow = (cellValues) => {
    // console.log(event);
    setDeleteId(cellValues.id);
    setDeleteOpen(true);
  };

  const handleDeleteDialogCancel = () => {
    setDeleteOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    setDeleteOpen(false);
    const response = await api.delete(`/admin/users/${deleteId}/hard-delete`);
    console.log(response);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.user + strings.deletedSuccessfully
      );
    }
    setPage(1);
    fetchData(1);
  };

  return (
    <div
      style={{
        width: 1000,
      }}
    >
      <div>
        <Layout
          style={{
            minHeight: "100vh",
            //set a width to the layout
            width: "105%",
          }}
        >
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title={strings.users}
                  extra={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        placeholder={strings.search}
                        prefix={<SearchOutlined />}
                        size="small"
                        style={{
                          marginRight: 25,
                        }}
                        onPressEnter={(event) => {
                          setSearch(event.target.value);
                          fetchData(page);
                        }}
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                          if (event.target.value === "" || null) {
                            setSearch(null);
                            fetchData(page, true);
                          }
                        }}
                        value={search}
                      />
                      <Button
                        onClick={() => {
                          setInputOpen(true);
                        }}
                      >
                        {strings.create}
                      </Button>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={newsColumns}
                      dataSource={Srows}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                        current: page,
                        //set number of pages
                        total: total,
                        showSizeChanger: false,
                      }}
                      onChange={(pagination) => {
                        setPage(pagination.current);
                        console.log(pagination.current);
                        fetchData(pagination.current);
                      }}
                      size="large"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>

      <Modal
        title={strings.create}
        open={inputOpen}
        footer={null}
        onCancel={handleInputDialogClose}
        inputData={inputData}
        width={1000}
      >
        <UsersInputForm
          openNotification={props.openNotification}
          fetchData={fetchData}
          page={page}
          open={[inputOpen, setInputOpen]}
          edit={false}
        />
      </Modal>
      {/* //Edit Input */}
      <Modal
        title={strings.edit}
        open={updateOpen}
        footer={null}
        onCancel={handleUpdateDialogClose}
        width={1000}
      >
        <UsersInputForm
          openNotification={props.openNotification}
          fetchData={fetchData}
          page={page}
          open={[updateOpen, setUpdateOpen]}
          edit={true}
          id={editData?.id}
        />
      </Modal>

      <Modal
        title="Are you sure you want to delete the item?"
        open={deleteOpen}
        onOk={handleDeleteDialogConfirm}
        onCancel={handleDeleteDialogCancel}
      ></Modal>
    </div>
  );
}

export default Users;
