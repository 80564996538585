import React, { useEffect, useState } from "react";
import { useRecoilState, atom } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { IconButton } from "@mui/material";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Space,
  AutoComplete,
  Layout,
  List,
  DatePicker,
  Typography,
  InputNumber,
  Divider,
  Upload,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { currentLangState } from "./Dashboard";
import dayjs from "dayjs";
import { LocalizationStrings } from "../utils/localization";
import moment from "moment";
import ClientCarInputForm from "./forms/ClientCarInputForm.tsx";
import VignetteInputForm from "./forms/VignetteInputForm";
import InsuranceInputForm from "./forms/InsuranceInputForm";
import LeaseInputForm from "./forms/LeaseInputForm";

const UpdateDialogState = atom({
  key: "UpdateDialogState",
  default: false,
});

const DeleteDialogState = atom({
  key: "DeleteDialogState",
  default: false,
});

const InputDialogState = atom({
  key: "InputDialogState",
  default: false,
});

function ClientCars(props) {
  let api = useAxios();
  const [selectedRow, setSelectedRow] = useState(null);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [Srows, setRows] = useState([]);
  const [editCar, setEditCar] = useState({});
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [deleteCar, setDeleteCar] = useState({});
  const [inputCar, setInputCar] = useState({});
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [vignettesOpen, setVignettesOpen] = useState(false);
  const [vignettes, setVignettes] = useState([]);
  const [createVignetteOpen, setCreateVignetteOpen] = useState(false);
  const [vignette, setVignette] = useState({
    clientCarId: null,
    durationType: null,
    startsAt: null,
    id: null,
  });
  const [deleteVignetteOpen, setDeleteVignetteOpen] = useState(false);
  // const [clientCarTableState, setClientCarTableState] = React.useState({});
  const [insuranceOpen, setInsuranceOpen] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [createInsuranceOpen, setCreateInsuranceOpen] = useState(false);
  const [insurance, setInsurance] = useState({
    clientCarId: null,
    type: null,
    startsAt: null,
    price: null,
    monthsNumber: null,
    paymentsNumber: null,
  });
  const [deleteInsuranceOpen, setDeleteInsuranceOpen] = useState(false);
  const [editInsuranceOpen, setEditInsuranceOpen] = useState(false);
  const [leaseOpen, setLeaseOpen] = useState(false);
  const [lease, setLease] = useState({
    clientCarId: null,
    type: null,
    startsAt: dayjs(),
    price: null,
    monthsNumber: null,
    paymentsNumber: null,
  });
  const [leases, setLeases] = useState([]);
  const [createLeaseOpen, setCreateLeaseOpen] = useState(false);
  const [deleteLeaseOpen, setDeleteLeaseOpen] = useState(false);
  const [editLeaseOpen, setEditLeaseOpen] = useState(false);
  const [editVignetteOpen, setEditVignetteOpen] = useState(false);
  const [editLease, setEditLease] = useState(null);
  const [csv, setCsv] = useState(null);
  const [paymentsOpen, setPaymentsOpen] = useState(false);

  const [search, setSearch] = useState("");

  const [editId, setEditId] = useState(null);

  const strings = LocalizationStrings;

  const [currentLang, setCurrentLang] = useRecoilState(currentLangState);
  strings.setLanguage(currentLang);

  const navigate = useNavigate();

  const clientCarColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 75,
    },
    {
      title: strings.brand,
      dataIndex: "brand",
      key: "brand",
      width: 100,
      sorter: (a, b) => a.brand.localeCompare(b.brand),
    },

    {
      title: strings.model,
      key: "model",
      dataIndex: "model",
      width: 100,
      sorter: (a, b) => a.model.localeCompare(b.model),
    },
    {
      title: strings.registrationNumber,
      key: "registration_umber",
      dataIndex: "registration_number",
      width: 60,
    },
    // {
    //   title: strings.vin,
    //   key: "vin",
    //   dataIndex: "vin",
    //   width: 100,
    // },
    {
      title: strings.user,
      key: "user",
      dataIndex: "user",
      width: 100,
      sorter: (a, b) => a.user.localeCompare(b.user),
    },
    {
      title: strings.options,
      key: "options",
      dataIndex: "options",
      width: 100,
      render: (text, record, index) => (
        <>
          <Button
            key="vignettes"
            // type="primary"
            className="tag-primary"
            onClick={() => {
              handleVignettesDialogOpen(record.id);
            }}
          >
            {strings.vignettesButton}
          </Button>
          <Button
            key="insurances"
            // type="primary"
            className="tag-primary"
            onClick={() => {
              handleInsuranceDialogOpen(record);
            }}
          >
            {strings.insurancesButton}
          </Button>
          <Button
            key="Lease"
            // type="primary"
            className="tag-primary"
            onClick={() => {
              setLease({ ...lease, clientCarId: record.id });
              handleLeaseDialogOpen(record);
            }}
          >
            {strings.leaseButton}
          </Button>
          <IconButton
            key="edit"
            type="primary"
            className="tag-primary"
            onClick={() => {
              //console log this row
              // console.log(record);
              setEditCar({ ...record, userId: null });
              setEditId(record.id);
              handleUpdateDialogClickOpen(record);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            key="delete"
            type="primary"
            className="tag-primary"
            onClick={() => {
              setDeleteCar(record);
              handleDeleteButtonClickRow(record);
            }}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton
            key="info"
            type="primary"
            className="tag-primary"
            onClick={() => {
              navigate(`/client-cars/${record.id}`);
            }}
          >
            <InfoCircleOutlined />
          </IconButton>
        </>
      ),
    },
  ];

  let isMounted = true;

  const fetchData = async (p, isEmpty = false) => {
    try {
      if (isMounted) {
        // console.log(headerConfig);
        const response = await api.get(`/admin/client-cars`, {
          params: {
            page: p,
            limit: 10,
            search: isEmpty ? "" : search,
            sortBy: "createdAt:DESC",
          },
        });
        // console.log(page);
        console.log(response);
        setTotal(response.data.data.meta.totalItems);
        var data = new Array();
        for (let i = 0; i < response.data.data.data.length; i++) {
          data.push(response.data.data.data[i]);
          if (response.data.data.data[i]["user"] != null) {
            data[i]["user"] = data[i]["user"]["email"];
            //add a key property
          }
          data[i]["key"] = data[i]["id"];
        }

        setRows(data);
        // console.log(Srows);

        // console.log("rows1 \n" + rows1);
        getUsers().then((res) => {
          // console.log("res: " + res);
          setUsersDropdown(res);
        });
      }
    } catch (error) {
      console.error(error);
      // props.logOut();
    }
  };

  const setTokens = () => {
    const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
    // console.log(user.role);
    if (user.role !== "admin") {
      alert("not admin");
      props.logOut();
      return;
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    setRefreshToken(localStorage.getItem("bovaRefreshToken"));
    // console.log(accessToken);
    if (
      (localStorage.getItem("bovaAccessToken") === "" &&
        localStorage.getItem("bovaRefreshToken") === "") ||
      (localStorage.getItem("bovaAccessToken") === null &&
        localStorage.getItem("bovaRefreshToken") === null)
    ) {
      window.location.href = "/signin";
    }
  };

  // pochti raboti tozi useeffect hook
  useEffect(() => {
    setTokens();

    fetchData(page);

    return;
  }, [accessToken, refreshToken]);

  const getUsers = async (filter) => {
    if (filter) {
      const response = await api.get("/admin/users", {
        params: {
          search: filter,
        },
      });
      return response.data.data.data;
    } else {
      const response = await api.get("/admin/users");
      // console.log(response);
      return response?.data.data.data;
    }
  };

  //diaog box logic
  const [updateOpen, setUpdateOpen] = useRecoilState(UpdateDialogState);
  const [deleteOpen, setDeleteOpen] = useRecoilState(DeleteDialogState);
  const [inputOpen, setInputOpen] = useRecoilState(InputDialogState);

  const handleUpdateDialogClickOpen = () => {
    // console.log(usersDropdown);
    setUpdateOpen(true);
    getUsers().then((res) => {
      setUsers(res);
    });
  };

  const handleUpdateCarDialogClose = () => {
    setUpdateOpen(false);
  };

  //delete dialog
  const handleDeleteDialogCancel = () => {
    setDeleteOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    // console.log("Delete Row " + selectedRow);
    console.log(deleteCar);
    const response = await api.delete("/admin/client-cars/" + deleteCar);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.car + strings.deletedSuccessfullyF
      );
    }
    console.log(response.status);
    setDeleteOpen(false);
    fetchData(page);
  };

  const handleDeleteButtonClickRow = async (cellValues) => {
    //open the delete dialog
    console.log(cellValues);
    setSelectedRow(cellValues.id);
    setDeleteCar(cellValues.id);
    setDeleteOpen(true);
  };

  const handleInputDialogClose = () => {
    setInputOpen(false);
  };

  const handleVignettesDialogOpen = async (record) => {
    // console.log(record);
    setVignettesOpen(true);
    setVignette({ clientCarId: record });

    const response = await api.get("/admin/vignettes", {
      params: {
        "filter.clientCar.id": record,
      },
    });
    console.log(response);
    setVignettes(response.data.data.data);
  };

  const handleVignettesDialogCancel = () => {
    setVignettesOpen(false);
  };

  const handleCreateVignetteDialogCancel = () => {
    setCreateVignetteOpen(false);
  };

  const handleDeleteVignette = async () => {
    api.delete("/admin/vignettes/" + vignette.id).then((res) => {
      console.log(res);
      handleVignettesDialogOpen(vignette?.clientCarId);
      if (res.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.vignette + strings.deletedSuccessfullyF
        );
      }
    });
    setDeleteVignetteOpen(false);
  };

  const handleInsuranceDialogCancel = () => {
    setInsuranceOpen(false);
  };

  const handleCreateInsuranceDialogCancel = () => {
    setCreateInsuranceOpen(false);
  };

  const handleInsuranceDialogOpen = async (record) => {
    setInsurance({ ...null, clientCarId: record.id });
    setInsuranceOpen(true);

    const response = await api.get("/admin/insurances", {
      params: {
        "filter.clientCar.id": record.id,
      },
    });
    console.log(response);
    setInsurances(
      response.data.data.data.filter((item) => item.clientCar?.id === record.id)
    );
  };

  const handleDeleteInsurance = async () => {
    api.delete("/admin/insurances/" + insurance.id).then((res) => {
      console.log(res);
      handleInsuranceDialogOpen({ id: insurance?.clientCarId });
      // setInsuranceOpen(false);
      setInsurance({ ...null, clientCarId: insurance?.clientCarId });
      if (res.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.insurance + strings.deletedSuccessfullyF
        );
      }
      setDeleteInsuranceOpen(false);
    });
  };

  //LEASE

  const handleLeaseDialogOpen = async (record) => {
    api
      .get("/admin/leases", {
        params: {
          "filter.clientCar.id": record.id,
        },
      })
      .then((res) => {
        console.log(res);
        let l = res?.data.data.data.filter(
          (item) => item.clientCar?.id === record?.id
        )[0];
        if (l === undefined) {
          setLeases({ ...null, clientCarId: record.id, noLease: true });
          setLeaseOpen(true);
          return;
        }
        api.get("/admin/leases/" + l.id).then((res) => {
          console.log(res);
          if (res.data.data !== undefined) {
            // console.log(l);
            setLeases({ ...res.data.data, clientCarId: record.id });
          } else {
            setLeases({ ...null, clientCarId: record.id });
          }
        });
      });
    setLeaseOpen(true);
  };

  const displayLease = () => {
    if (!leases || Object.keys(leases).length === 0 || leases.noLease) {
      return (
        <div>
          <Divider />
          <Typography>{strings.noLease}</Typography>
          <br />
          <Button
            onClick={() => {
              setCreateLeaseOpen(true);
            }}
          >
            {strings.create}
          </Button>
        </div>
      );
    } else {
      return (
        //display lease

        <div>
          <Divider />
          <div>
            <Typography>
              {strings.startsAt}
              {": "}
              {leases?.startsAt
                ? dayjs(leases?.startsAt).format("DD/MM/YYYY HH:mm")
                : ""}{" "}
              <br />
              {strings.endsAt}
              {": "}
              {leases?.endsAt
                ? dayjs(leases?.endsAt).format("DD/MM/YYYY HH:mm")
                : ""}{" "}
              <br />
              {strings.paymentsNumber}: {leases?.paymentsNumber} <br />
              {strings.paidPaymentsNumber}: {leases?.paidPaymentsNumber} <br />
              {strings.price}: {leases?.price} <br />
              {strings.interestRate}: {leases?.interestRate} <br />
              {strings.downPayment}: {leases?.downPayment} <br />
              {strings.residualPayment}: {leases?.residualPayment} <br />
              {/* {strings.payments}:{" "}
              {leases?.payments?.map((item) => {
                return (
                  <div>
                    {item?.paymentDate
                      ? dayjs(item?.paymentDate).format("DD/MM/YYYY HH:mm")
                      : ""}{" "}
                    {item?.amount} <br />
                  </div>
                );
              })} */}
            </Typography>
          </div>
          <Divider />
          <div>
            <Space
              direction="horizontal"
              style={{ width: "50%", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => {
                  api.get("/admin/leases/" + leases?.id).then((res) => {
                    console.log(res);
                    setLease({
                      clientCarId: lease?.clientCarId,
                      ...res.data.data,
                    });
                    setEditLeaseOpen(true);
                  });
                }}
              >
                {strings.edit}
              </Button>
              <Button
                onClick={() => {
                  setPaymentsOpen(true);
                }}
              >
                {strings.payments}
              </Button>
              <Upload
                name="file"
                fileList={csv}
                onRemove={(e) => {
                  setCsv([]);
                }}
                customRequest={(e) => {
                  console.log(e);
                  api
                    .post(
                      "/admin/leases/payments",
                      {
                        leaseId: leases?.id,
                        file: e.file,
                      },
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      setCsv([
                        {
                          name: e.file.name,
                          status: "done",
                          uid: 1,
                        },
                      ]);
                      console.log(res);
                      if (res.status === 201) {
                        props.openNotification(
                          "topRight",
                          strings.success,
                          strings.payments + strings.addedSuccessfullyP
                        );
                        handleLeaseDialogOpen({ id: lease?.clientCarId });
                      }
                    });
                }}
              >
                <Button>{strings.importCSV}</Button>
              </Upload>
              <Button
                danger={true}
                onClick={() => {
                  setDeleteLeaseOpen(true);
                }}
              >
                {strings.delete}
              </Button>
            </Space>
          </div>
        </div>
      );
    }
  };

  const displayPayments = () => {
    if (leases?.payments?.length === 0) {
      return (
        <div>
          <Typography>{strings.noPayments}</Typography>
        </div>
      );
    } else {
      return (
        <div>
          <Table
            dataSource={leases?.payments}
            size="small"
            pagination={{
              pageSize: 12,
              total: leases?.payments?.length,
              showSizeChanger: false,
              position: ["bottomCenter"],
            }}
            onChange={(e) => {
              console.log(e);
            }}
            columns={[
              {
                dataIndex: "number",
                key: "number",
                title: strings.number,
                render: (text, record, index) => {
                  return record.number;
                },
              },
              {
                dataIndex: "paymentDate",
                key: "paymentDate",
                title: strings.date,
                render: (text, record) => {
                  return dayjs(record?.date).format("DD/MM/YYYY HH:mm");
                },
              },
              {
                dataIndex: "amount",
                key: "amount",
                title: strings.price,
                render: (text, record) => {
                  return record?.price;
                },
              },
              {
                dataIndex: "interest",
                key: "interest",
                title: strings.interest,
                render: (text, record) => {
                  return record?.interest;
                },
              },
              {
                dataIndex: "tax",
                key: "tax",
                title: strings.tax,
                render: (text, record) => {
                  return record?.tax;
                },
              },
              {
                dataIndex: "principalBalance",
                key: "principalBalance",
                title: strings.principalBalance,
                render: (text, record) => {
                  return record?.principalBalance;
                },
              },
            ]}
          />
        </div>
      );
    }
  };

  const handleEditLeaseSubmit = async () => {
    if (
      lease?.startsAt === "" ||
      lease?.price === "" ||
      lease?.paymentsNumber === "" ||
      lease?.interestRate === "" ||
      lease?.residualPayment === "" ||
      lease?.downPayment === ""
    ) {
      alert("Please fill out all fields");
      return;
    }
    const req = {
      startsAt: lease?.startsAt,
      clientCarId: lease?.clientCarId,
      price: lease?.price.toString(),
      paymentsNumber: parseInt(lease?.paymentsNumber),
      interestRate: lease?.interestRate.toString(),
      residualPayment: lease?.residualPayment.toString(),
      downPayment: lease?.downPayment.toString(),
    };
    console.log(req);
    const res = await api.put("/admin/leases/" + lease?.id, req);
    if (res.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.lease + strings.updatedSuccessfully
      );
    }
    console.log(res);
    setEditLeaseOpen(false);
    handleLeaseDialogOpen({ id: lease?.clientCarId });
  };

  const switchDurationType = (type) => {
    switch (type) {
      case "Weekend":
        return strings.weekend;
      case "Week":
        return strings.week;
      case "Month":
        return strings.month;
      case "Three months":
        return strings.threeMonths;

      case "Year":
        return strings.year;

      default:
        break;
    }
  };

  return (
    <div
      style={{
        width: 1000,
      }}
    >
      <div>
        <Layout
          style={{
            minHeight: "100vh",
            width: "115%",
            //set a width to the layout
          }}
        >
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title={strings.clientCars}
                  extra={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        placeholder={strings.search}
                        prefix={<SearchOutlined />}
                        size="small"
                        style={{
                          marginRight: 25,
                        }}
                        onPressEnter={(event) => {
                          setSearch(event.target.value);
                          fetchData(page);
                        }}
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                          console.log(event.target.value);
                          if (event.target.value === "" || null) {
                            setSearch(null);
                            fetchData(page, true);
                          }
                        }}
                        value={search}
                      />
                      <Button
                        onClick={() => {
                          setInputOpen(true);
                          getUsers().then((res) => {
                            setUsers(res);
                          });
                        }}
                      >
                        {strings.create}
                      </Button>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={clientCarColumns}
                      dataSource={Srows}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                        current: page,
                        //set number of pages
                        total: total,
                      }}
                      onChange={(pagination) => {
                        setPage(pagination.current);
                        console.log(pagination.current);
                        fetchData(pagination.current);
                      }}
                      size="large"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>

      <Modal
        title={strings.create}
        open={inputOpen}
        onCancel={handleInputDialogClose}
        width={700}
        footer={null}
      >
        <Divider />
        <ClientCarInputForm
          openNotification={props.openNotification}
          fetchData={fetchData}
          page={page}
          open={[inputOpen, setInputOpen]}
          edit={false}
        />
      </Modal>
      <Modal
        title={strings.edit}
        open={updateOpen}
        onCancel={handleUpdateCarDialogClose}
        footer={null}
      >
        <Divider />
        <ClientCarInputForm
          openNotification={props.openNotification}
          fetchData={fetchData}
          page={page}
          open={[updateOpen, setUpdateOpen]}
          edit={true}
          id={editId}
        />
      </Modal>
      {/* delete dialog */}
      <Modal
        title={strings.deleteConfirmation}
        open={deleteOpen}
        onOk={handleDeleteDialogConfirm}
        onCancel={handleDeleteDialogCancel}
      ></Modal>
      <Modal
        title={strings.vignettes}
        open={vignettesOpen}
        // onOk={handleVignettesDialogSubmit}
        onCancel={handleVignettesDialogCancel}
        footer={
          <div>
            <Button
              onClick={() => {
                setCreateVignetteOpen(true);
              }}
            >
              {strings.create}
            </Button>
          </div>
        }
        width={1000}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <List
            size="small"
            dataSource={vignettes}
            renderItem={(item) => (
              <List.Item>
                <Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setVignette({ ...vignette, id: item.id });
                      setDeleteVignetteOpen(true);
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>{" "}
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditVignetteOpen(true);
                      setVignette({
                        ...vignette,
                        id: item.id,
                        clientCarId: item.clientCar.id,
                      });
                      console.log(item);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  - id: {item.id} - {strings.duration}:{" "}
                  {dayjs(item.startsAt).format("DD/MM/YYYY HH:mm")} -{" "}
                  {dayjs(item.endsAt).format("DD/MM/YYYY HH:mm")} -{" "}
                  {switchDurationType(item?.durationType)} -{" "}
                </Typography>
              </List.Item>
            )}
          />
        </Space>
      </Modal>
      <Modal
        title={strings.create}
        open={createVignetteOpen}
        onCancel={handleCreateVignetteDialogCancel}
        footer={null}
      >
        <VignetteInputForm
          openNotification={props.openNotification}
          fetchData={(id) => {
            api
              .get(`admin/vignettes`, {
                params: {
                  "filter.clientCar.id": id,
                },
              })
              .then((res) => {
                console.log(res);
                setVignettes(res.data.data.data);
              });
          }}
          page={page}
          open={[createVignetteOpen, setCreateVignetteOpen]}
          edit={false}
          id={vignette?.clientCarId}
        />
      </Modal>
      <Modal
        title={strings.edit}
        open={editVignetteOpen}
        onCancel={() => {
          setEditVignetteOpen(false);
        }}
        footer={null}
      >
        <VignetteInputForm
          openNotification={props.openNotification}
          fetchData={(id) => {
            api
              .get(`admin/vignettes`, {
                params: {
                  "filter.clientCar.id": id,
                },
              })
              .then((res) => {
                console.log(res);
                setVignettes(res.data.data.data);
              });
          }}
          page={page}
          open={[editVignetteOpen, setEditVignetteOpen]}
          edit={true}
          id={vignette?.id}
          clientCarId={vignette?.clientCarId}
        />
      </Modal>
      <Modal
        title={strings.deleteConfirmation}
        open={deleteVignetteOpen}
        onOk={handleDeleteVignette}
        onCancel={() => {
          setDeleteVignetteOpen(false);
        }}
      ></Modal>
      {/* insurance modal */}
      <Modal
        title={strings.insurances}
        open={insuranceOpen}
        // onOk={handleVignettesDialogSubmit}
        onCancel={handleInsuranceDialogCancel}
        footer={
          <div>
            <Button
              onClick={() => {
                setCreateInsuranceOpen(true);
              }}
            >
              {strings.create}
            </Button>
          </div>
        }
        width={1000}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <List
            size="small"
            dataSource={insurances}
            renderItem={(item) => (
              <List.Item>
                <Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setInsurance({ ...insurance, id: item.id });
                      setDeleteInsuranceOpen(true);
                    }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={async () => {
                      api.get(`admin/insurances/${item.id}`).then((res) => {
                        setInsurance({
                          ...res.data.data,
                          clientCarId: insurance.clientCarId,
                        });
                        console.log(res.data.data);
                      });
                      setEditInsuranceOpen(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  - id: {item.id} - {strings.monthsNumber}: {item.monthsNumber}{" "}
                  - - {strings.startsAt}: -{" "}
                  {dayjs(item.startsAt).format("DD/MM/YYYY HH:mm")} -{" "}
                  {strings.paidPaymentsNumber}: {item.paidPaymentsNumber} -{" "}
                  {strings.paymentsNumber}: {item.paymentsNumber}
                </Typography>
              </List.Item>
            )}
          />
        </Space>
      </Modal>

      <Modal
        title={strings.create}
        open={createInsuranceOpen}
        onCancel={handleCreateInsuranceDialogCancel}
        footer={null}
      >
        <InsuranceInputForm
          openNotification={props.openNotification}
          fetchData={async (id) => {
            api
              .get(`admin/insurances`, {
                params: {
                  "filter.clientCar.id": id,
                },
              })
              .then((res) => {
                console.log(res);
                setInsurances(
                  res.data.data.data.filter((item) => item.clientCar?.id === id)
                );
              });
          }}
          page={page}
          open={[createInsuranceOpen, setCreateInsuranceOpen]}
          edit={false}
          id={insurance?.clientCarId}
        />
      </Modal>
      <Modal
        title={strings.deleteConfirmation}
        open={deleteInsuranceOpen}
        onOk={handleDeleteInsurance}
        onCancel={() => {
          setDeleteInsuranceOpen(false);
        }}
      ></Modal>
      {/* Edit Insurance Modal */}
      <Modal
        title={strings.edit}
        open={editInsuranceOpen}
        onCancel={() => {
          setEditInsuranceOpen(false);
        }}
        footer={null}
      >
        <InsuranceInputForm
          openNotification={props.openNotification}
          fetchData={async (id) => {
            api
              .get(`admin/insurances`, {
                params: {
                  "filter.clientCar.id": id,
                },
              })
              .then((res) => {
                console.log(res);
                setInsurances(
                  res.data.data.data.filter((item) => item.clientCar?.id === id)
                );
              });
          }}
          page={page}
          open={[editInsuranceOpen, setEditInsuranceOpen]}
          edit={true}
          id={insurance?.id}
          clientCarId={insurance?.clientCarId}
        />
      </Modal>
      {/* LEASE MODAL */}
      <Modal
        title={strings.lease}
        open={leaseOpen}
        onOk={() => {
          setLeaseOpen(false);
        }}
        onCancel={() => {
          setLeaseOpen(false);
        }}
        width={800}
      >
        <Typography>{displayLease()}</Typography>
      </Modal>
      {/* CREATE LEASE MODAL */}
      <Modal
        title={strings.create}
        open={createLeaseOpen}
        onCancel={() => {
          setCreateLeaseOpen(false);
          setLease(null);
        }}
        footer={null}
      >
        <LeaseInputForm
          openNotification={props.openNotification}
          fetchData={() => {
            handleLeaseDialogOpen({ id: lease?.clientCarId });
          }}
          page={page}
          open={[createLeaseOpen, setCreateLeaseOpen]}
          edit={false}
          id={lease?.clientCarId}
        />
      </Modal>
      <Modal
        title={strings.deleteConfirmation}
        open={deleteLeaseOpen}
        onOk={() => {
          // console.log(leases);
          api.delete(`admin/leases/${leases?.id}`).then((res) => {
            if (res.status === 200) {
              props.openNotification(
                "topRight",
                strings.success,
                strings.lease + strings.deletedSuccessfully
              );
            }
            console.log(res);
            setDeleteLeaseOpen(false);
            setLease(null);
            setLeaseOpen(false);
          });
        }}
        onCancel={() => {
          setDeleteLeaseOpen(false);
        }}
      ></Modal>
      {/* EDIT LEASE MODAL */}
      <Modal
        title={strings.edit}
        open={editLeaseOpen}
        onOk={handleEditLeaseSubmit}
        onCancel={() => {
          setEditLeaseOpen(false);
          setLease(null);
        }}
        footer={null}
      >
        <LeaseInputForm
          openNotification={props.openNotification}
          fetchData={() => {
            handleLeaseDialogOpen({ id: lease?.clientCarId });
          }}
          page={page}
          open={[editLeaseOpen, setEditLeaseOpen]}
          edit={true}
          id={lease?.id}
          clientCarId={lease?.clientCarId}
        />
      </Modal>
      <Modal
        title={strings.payments}
        open={paymentsOpen}
        width={800}
        onCancel={() => {
          setPaymentsOpen(false);
        }}
        footer={
          <Button onClick={() => setPaymentsOpen(false)}>
            {strings.close}
          </Button>
        }
      >
        <Typography>{displayPayments()}</Typography>
      </Modal>
    </div>
  );
}

export default ClientCars;
