import { ConsoleSqlOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useMemo } from "react";
import { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { atom, useRecoilState } from "recoil";
import { inputNewsState, editNewsState } from "./News";
import { Quill } from "react-quill";
import { useState } from "react";
import { inputEventState, editEventState } from "./Events";
import { editOffersRTBState, inputOffersRTBState } from "./OffersRTB";

export const quillObjState = atom({
  key: "quillObjState",
  default: null,
});

//create an image blot embed
class ImageBlot extends Quill.import("blots/block/embed") {
  static create(value) {
    var node = super.create();
    node.setAttribute("src", value);
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";

Quill.register(ImageBlot);

type Props = {
  value: string;
  placeholder: string;

  actionType:
    | "inputNews"
    | "editNews"
    | "inputEvent"
    | "editEvent"
    | "inputOffer"
    | "editOffer";
};
type ActionType =
  | "inputNews"
  | "editNews"
  | "inputEvent"
  | "editEvent"
  | "inputOffer"
  | "editOffer";

function getRecoilState(actionType: ActionType) {
  switch (actionType) {
    case "inputNews":
      return inputNewsState;
    case "editNews":
      return editNewsState;
    case "inputEvent":
      return inputEventState;
    case "editEvent":
      return editEventState;
    case "inputOffer":
      return inputOffersRTBState;
    case "editOffer":
      return editOffersRTBState;
    default:
      throw new Error(`Invalid action type: ${actionType}`);
  }
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

const QuillTextBox: React.FC<Props> = ({ value, placeholder, actionType }) => {
  // const quillObj = useRef<ReactQuill>(null);
  var quillObj = useRef<ReactQuill>(null);
  const forceUpdate = useForceUpdate();
  const [quillText, setQuilltext] = useRecoilState<any>(quillObjState);

  const [currentState, stateSet] = useState<any>({
    textDelta: "",
    textHtml: "",
  });
  const [upperState, setUpperState] = useRecoilState<any>(
    getRecoilState(actionType)
  );

  const handleChange = useMemo(() => {
    let previousValue = currentState;
    return (value, delta, source, editor) => {
      const newValue = {
        textDelta: editor ? editor.getContents() : null,
        textHtml: value,
      };

      setQuilltext(value);

      if (
        JSON.stringify(newValue) !== JSON.stringify(previousValue) // check if new state is different from current state
      ) {
        previousValue = newValue;
        stateSet(newValue);
        setUpperState((oldState) => ({
          ...oldState,
          textDelta: editor ? editor.getContents() : null,
          textHtml: value,
        }));
      }
      forceUpdate();
    };
  }, [currentState, setUpperState, stateSet, quillText]);

  const imageHandler = async () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files) {
        const fileUploadUrl = `${process.env.REACT_APP_BASE_URL}/admin/file-upload/upload-signed-url`;

        var file: any = input.files[0];

        var fileName = file.name;

        //make a get request with the file name and type
        const res = await axios.get(fileUploadUrl, {
          params: {
            filename: fileName,
            mediaType: file.type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bovaAccessToken")}`,
          },
        });
        console.log(res.data);
        const url = res.data.data.url;
        const newFileName = res.data.data.filename;
        const res2 = await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read",
          },
        });
        console.log(res2);

        const res3 = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/admin/file-upload/public-url?`,
          {
            params: {
              filename: newFileName,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "bovaAccessToken"
              )}`,
            },
          }
        );
        var fetchImageUrl = res3.data.data.url;
        console.log(res3.data.data.url);
        if (quillObj?.current === null) return;
        const range = quillObj?.current.getEditorSelection();
        // console.log(range);
        if (range === null) return;
        try {
          // quillObj.current
          //   .getEditor()
          //   .insertEmbed(range.index, "image", fetchImageUrl);
          // quillObj.current.editor?.insertEmbed(
          //   range.index,
          //   "image",
          //   fetchImageUrl,
          //   "api"
          // );
          // add the image to the quill textbox at the current cursor position and update the state
          quillObj.current
            .getEditor()
            .insertEmbed(range.index, "image", fetchImageUrl);
          setUpperState((oldState) => ({
            ...oldState,
            image: fetchImageUrl,
          }));

          // console.log(quillObj.current.getEditor().getContents());
        } catch (e) {
          console.log(quillObj);
          console.log(e);
        }
      } else {
        alert("No file selected");
      }
    };
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "link",
    "code",
    "image",
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <>
      <ReactQuill
        // defaultValue={value ? JSON.parse(value) : ""}
        value={quillText}
        ref={quillObj}
        theme="snow"
        modules={modules}
        // formats={formats}
        onChange={handleChange}
      />
    </>
  );
};

export default QuillTextBox;
