import React from 'react';
import { Form, Input, Button, Typography, Row, Col } from 'antd';
import './SupportForm.css';
import logo from '../assets/images/bmw-logo.svg';
import useAxios from "../utils/useAxios";
import { LocalizationStrings } from "../utils/localization";
import {useRecoilState} from "recoil";
import {currentLangState} from "./Dashboard";

const { Title } = Typography;


function SupportForm() {
    const [form] = Form.useForm();
    const api = useAxios();
    const strings = LocalizationStrings;
    const [currentLang, setCurrentLang] = useRecoilState(currentLangState);
    strings.setLanguage(currentLang);

    const onFinish = async (values) => {
        console.log('Form values:', values);
        try {
            const res = await api.post("/support", values);

        } catch (error) {
            console.error(strings.errorSendingProblem, error);
            alert("There was an error. Please try again.");
        }
        alert(strings.success);
        form.resetFields();
    };

    return (
        <div className="support-form-container">
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <img className="logoBMW" src={logo} alt="Logo" />
                    <h1 className="name">{strings.byBovaCar}</h1>
                    <Title level={3} className="form-title">{strings.supportForm}</Title>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        className="support-form"
                    >
                        <Form.Item
                            name="email"
                            label={strings.emailOptional}
                        >
                            <Input
                                type="email"
                                placeholder={strings.enterYourEmail}
                            />
                        </Form.Item>
                        <Form.Item
                            name="problem"
                            label={strings.problem}
                            rules={[{ required: true, message: strings.pleaseDescribeYourProblem }]}
                        >
                            <Input.TextArea
                                rows={4}
                                placeholder={strings.describeYourProblem}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="submit-btn">
                                {strings.submit}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default SupportForm;
