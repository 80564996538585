import React from "react";
import { Form, Input, Button, Space, AutoComplete, Divider } from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";

type Props = {
  openNotification: (placement: any, message: any, description: any) => void;
  fetchData: (page: number) => void;
  page: number;
  open: (inputOpen: boolean) => void;
  edit: boolean;
  id: number | null;
};

const strings = LocalizationStrings;

const ClientCarInputForm: React.FC<Props> = (props: Props) => {
  const api = useAxios();
  const [data, setData] = React.useState<any>({});
  const [users, setUsers] = React.useState<any>([]);
  const [usersDropdown, setUsersDropdown] = React.useState<any>([]);

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const req = {
      brand: data?.brand,
      model: data?.model,
      registration_number: data?.registration_number,
      vin: data?.vin,
      userId: data?.userId,
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/client-cars/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.car + strings.updatedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    } else {
      const response = await api.post("/admin/client-cars/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.car + strings.addedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = async (filter) => {
    if (filter) {
      const response = await api.get("/admin/users", {
        params: {
          search: filter,
        },
      });
      return response.data.data.data;
    } else {
      const response = await api.get("/admin/users");
      // console.log(response);
      return response?.data.data.data;
    }
  };

  const searchUsers = (event) => {
    // console.log(event);
    getUsers(event).then((res) => {
      setUsers(res);
      if (event.length > 0) {
        // console.log("res: " + res);
        const filteredUsers = users.filter((user) => {
          return user?.email.toLowerCase().includes(event.toLowerCase());
        });
        // console.log(filteredUsers);
        setUsersDropdown(filteredUsers);
      } else {
        // console.log("res: " + res);
        setUsersDropdown(users);
      }
    });
  };

  React.useEffect(() => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          brand: "",
          model: "",
          registration_number: "",
          vin: "",
          user_id: "",
        });
      } else if (props.edit) {
        api.get("/admin/client-cars/" + props.id).then((res) => {
          //   console.log(res);
          setData({ ...res.data.data, userId: res.data.data.user.id });
          //   console.log(res.data.data);
          form.setFieldsValue({
            brand: res.data.data.brand,
            model: res.data.data.model,
            registration_number: res.data.data.registration_number,
            vin: res.data.data.vin,
            user_id: res.data.data.user.email,
          });
        });
      }
      getUsers(null).then((res) => {
        setUsers(res);
        setUsersDropdown(res);
      });
    }
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Form
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          form={form}
          name="basic"
          initialValues={{
            brand: "",
            model: "",
            registration_number: "",
            vin: "",
            user_id: "",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={strings.brand}
            name="brand"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.brand.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <Input
              placeholder={strings.brand}
              value={data?.brand ? data?.brand : ""}
              onChange={(e) => setData({ ...data, brand: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label={strings.model}
            name="model"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.model.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <Input
              placeholder={strings.model}
              value={data?.model ? data?.model : ""}
              onChange={(e) => setData({ ...data, model: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label={strings.registrationNumber}
            name="registration_number"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.registrationNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input
              placeholder={strings.registrationNumber}
              value={data?.registration_number}
              onChange={(e) =>
                setData({ ...data, registration_number: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label={strings.vin}
            name="vin"
            rules={[
              {
                required: true,
                message: strings.pleaseInput + strings.vin + "!",
              },
            ]}
          >
            <Input
              placeholder={strings.vin}
              value={data?.vin}
              onChange={(e) => setData({ ...data, vin: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label={strings.user}
            name="user_id"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.user.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <AutoComplete
              size="large"
              value={data?.user}
              options={
                //get only the emails from the usersdropdown state}
                usersDropdown?.map((user) => {
                  return { value: user?.email };
                })
              }
              style={{ width: "100%" }}
              onSearch={(e) => searchUsers(e)}
              onSelect={(e) => {
                console.log(e);
                for (let i = 0; i < usersDropdown.length; i++) {
                  if (usersDropdown[i]?.email === e) {
                    setData({
                      ...data,
                      userId: usersDropdown[i]?.id,
                      user: e,
                    });
                    console.log("match");
                  }
                }
              }}
              // onSearch={onSearch}
              placeholder={strings.user}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "135%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default ClientCarInputForm;
