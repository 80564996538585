import React from "react";
import { Form, Input, Button, Space, AutoComplete, Divider } from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

type Props = {
  openNotification: (placement: any, message: any, description: any) => void;
  fetchData: (page: number) => void;
  page: number;
  open: (inputOpen: boolean) => void;
  edit: boolean;
  id: number | null;
};

const strings = LocalizationStrings;

const RequestsInputForm: React.FC<Props> = (props: Props) => {
  const api = useAxios();
  const [data, setData] = React.useState<any>({});

  const [users, setUsers] = React.useState<any>([]);
  const [usersDropdown, setUsersDropdown] = React.useState<any>([]);

  const [reqState, setReqState] = React.useState<any>({});

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const req = {
      userId: props.edit ? reqState?.userId : data?.userId,
      description: data?.description,
      showroomCarId: props.edit ? reqState.showroomCarId : data?.showroomCarId,
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/requests/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.request + strings.updatedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    } else {
      const response = await api.post("/admin/requests/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.request + strings.addedSuccessfullyF
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getUsers = async (filter) => {
    if (filter) {
      const response = await api.get("/admin/users", {
        params: {
          search: filter,
        },
      });
      return response.data.data.data;
    } else {
      const response = await api.get("/admin/users");
      // console.log(response);
      return response?.data.data.data;
    }
  };

  const searchUsers = (event) => {
    // console.log(event);
    getUsers(event).then((res) => {
      setUsers(res);
      if (event.length > 0) {
        // console.log("res: " + res);
        const filteredUsers = users.filter((user) => {
          return user?.email.toLowerCase().includes(event.toLowerCase());
        });
        // console.log(filteredUsers);
        setUsersDropdown(filteredUsers);
      } else {
        // console.log("res: " + res);
        setUsersDropdown(users);
      }
    });
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          title: "",
          description: "",
          showroomCarId: "",
        });
      } else if (props.edit) {
        api.get("/admin/requests/" + props.id).then((res) => {
          console.log(res);
          setData({ ...res.data.data });
          setReqState({
            userId: res.data.data.user.id,
            description: res.data.data.description,
            showroomCarId: res.data.data.showroomCar.id,
          });
          //   console.log(res.data.data);
          form.setFieldsValue({
            user: res.data.data.user.email,
            description: res.data.data.description,
            showroomCarId: res.data.data.showroomCarId,
          });
        });
      }
      getUsers(null).then((res) => {
        setUsers(res);
        setUsersDropdown(res);
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Divider />
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={strings.description}
            name="description"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.description.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <TextArea
              placeholder={strings.description}
              rows={3}
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </Form.Item>
          {!props.edit ? (
            <div>
              <Form.Item
                label={strings.user}
                name="user"
                rules={[
                  {
                    required: true,
                    message:
                      strings.pleaseInput +
                      strings.user.toLocaleLowerCase() +
                      "!",
                  },
                ]}
              >
                <AutoComplete
                  size="large"
                  value={data?.user}
                  options={
                    //get only the emails from the usersdropdown state}
                    usersDropdown?.map((user) => {
                      return { value: user?.email };
                    })
                  }
                  style={{ width: "100%" }}
                  onSearch={(e) => searchUsers(e)}
                  onSelect={(e) => {
                    console.log(e);
                    for (let i = 0; i < usersDropdown.length; i++) {
                      if (usersDropdown[i]?.email === e) {
                        setData({
                          ...data,
                          userId: usersDropdown[i]?.id,
                          user: e,
                        });
                        console.log("match");
                      }
                    }
                  }}
                  // onSearch={onSearch}
                  placeholder={strings.user}
                />
              </Form.Item>
              <Form.Item
                label={strings.showroomCar}
                name="title"
                rules={[
                  {
                    required: true,
                    message:
                      strings.pleaseInput +
                      strings.showroomCar.toLocaleLowerCase() +
                      " ID" +
                      "!",
                  },
                ]}
              >
                <Input
                  placeholder={strings.showroomCar + " ID"}
                  value={data?.showroomCarId}
                  onChange={(e) =>
                    setData({ ...data, showroomCarId: e.target.value })
                  }
                />
              </Form.Item>
            </div>
          ) : null}

          <Divider />
          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "90%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default RequestsInputForm;
