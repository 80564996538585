import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function SignInChecker() {
  useEffect(() => {
    console.log(localStorage.getItem("bovaAccessToken"));
    console.log(localStorage.getItem("bovaRefreshToken"));
    if (
      localStorage.getItem("bovaAccessToken") !== "" &&
      localStorage.getItem("bovaRefreshToken") !== "" &&
      localStorage.getItem("bovaAccessToken") !== null &&
      localStorage.getItem("bovaRefreshToken") !== null &&
      localStorage.getItem("bovaAccessToken") !== "undefined" &&
      localStorage.getItem("bovaRefreshToken") !== "undefined"
    ) {
      window.location.href = "/dashboard/client-cars";
    } else {
      window.location.href = "/signin";
    }
  }, []);

  return <div>a</div>;
}

export default SignInChecker;
