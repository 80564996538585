import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import useAxios from '../utils/useAxios';
import { accessTokenState, refreshTokenState } from '../App';
import jwtDecode from 'jwt-decode';
import { IconButton } from '@mui/material';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Card, Table, Button, Input, Modal } from 'antd';
import { Layout } from 'antd';
import dayjs from 'dayjs';

import { currentLangState } from './Dashboard';
import { LocalizationStrings } from '../utils/localization';

function OfferRequestsByEmail(props) {
    let api = useAxios();
    const navigate = useNavigate();
    const { email } = useParams(); // Get email parameter from the route

    const strings = LocalizationStrings;
    const [currentLang, setCurrentLang] = useRecoilState(currentLangState);
    strings.setLanguage(currentLang);

    const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
    const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
    const [rows, setRows] = useState([]);
    const [editData, setEditData] = useState(null);
    const [inputData, setInputData] = useState(null);
    const [inputOpen, setInputOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 75,
        },
        {
            title: strings.firstName,
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: strings.lastName,
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: strings.phone,
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: strings.email,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: strings.carModel,
            dataIndex: 'carModel',
            key: 'carModel',
        },
        {
            title: strings.comment,
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: strings.createdAt,
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
        },
        {
            title: strings.updatedAt,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 200,
        },
        {
            title: strings.options,
            key: 'options',
            dataIndex: 'options',
            width: 150,
            render: (text, record) => (
                <>
                    <IconButton
                        key="edit"
                        type="primary"
                        className="tag-primary"
                        onClick={() => {
                            setEditData(record);
                            handleUpdateDialogClickOpen(record.id);
                        }}
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        key="delete"
                        type="primary"
                        className="tag-primary"
                        onClick={() => {
                            setDeleteId(record.id);
                            handleDeleteButtonClickRow(record);
                        }}
                    >
                        <DeleteOutlined />
                    </IconButton>
                </>
            ),
        },
    ];

    let isMounted = true;

    const fetchData = async (p, isSearchEmpty) => {
        try {
            if (isMounted) {
                const response = await api.get(`/admin/offer-request/email/${email}`);
                const newData = response.data.data.map((item) => ({
                    ...item,
                    createdAt: dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                    updatedAt: dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
                }));
                setRows(newData);
                setTotal(response.data.data.length); // Adjust according to your pagination logic
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const user = jwtDecode(localStorage.getItem('bovaAccessToken'));
        if (user.role !== 'admin') {
            alert('not admin');
            props.logOut();
            return;
        }
        setAccessToken(localStorage.getItem('bovaAccessToken'));
        setRefreshToken(localStorage.getItem('bovaRefreshToken'));

        if (!localStorage.getItem('bovaAccessToken') || !localStorage.getItem('bovaRefreshToken')) {
            navigate('/signin');
        }

        fetchData(page);
        return () => (isMounted = false);
    }, [email, page, navigate, props, setAccessToken, setRefreshToken]);

    const handleUpdateDialogClickOpen = (recordId) => {
        setEditData(recordId);
        setUpdateOpen(true);
    };

    const handleDeleteButtonClickRow = (record) => {
        setDeleteId(record.id);
        setDeleteOpen(true);
    };

    const handleDeleteDialogCancel = () => {
        setDeleteOpen(false);
    };

    const handleDeleteDialogConfirm = async () => {
        setDeleteOpen(false);
        try {
            const response = await api.delete(`/admin/offer-request/${deleteId}`);
            if (response.status === 200) {
                props.openNotification('topRight', strings.success, strings.request + strings.deletedSuccessfullyF);
                fetchData(page);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (event) => {
        setSearch(event.currentTarget.value);
    };

    const handleSearch = () => {
        fetchData(page);
    };

    const handleInputOpen = () => {
        setInputOpen(true);
    };

    const handleInputClose = () => {
        setInputData({});
        setInputOpen(false);
    };

    const handleUpdateClose = () => {
        setEditData({});
        setUpdateOpen(false);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Row gutter={[24, 0]}>
                <Col xs={24} xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={strings.offerRequests}
                        extra={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Input
                                    placeholder={strings.search}
                                    prefix={<SearchOutlined />}
                                    size="small"
                                    style={{ marginRight: 25 }}
                                    onPressEnter={handleSearch}
                                    onChange={handleInputChange}
                                    value={search}
                                />
                                <Button onClick={handleInputOpen}>{strings.create}</Button>
                            </div>
                        }
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={rows}
                                className="ant-border-space"
                                pagination={{
                                    pageSize: 10,
                                    current: page,
                                    total: total,
                                    showSizeChanger: false,
                                }}
                                onChange={(pagination) => {
                                    setPage(pagination.current);
                                    fetchData(pagination.current);
                                }}
                                size="large"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>


            <Modal
                title="Are you sure you want to delete the item?"
                visible={deleteOpen}
                onOk={handleDeleteDialogConfirm}
                onCancel={handleDeleteDialogCancel}
            ></Modal>
        </Layout>
    );
}

export default OfferRequestsByEmail;