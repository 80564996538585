import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Input, Button, Typography, Space, Card, Image } from "antd";
import logo from "../assets/images/bmw-logo.png";

const theme = createTheme();

export default function SignIn(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("bovaAccessToken") !== "" &&
      localStorage.getItem("bovaRefreshToken") !== "" &&
      localStorage.getItem("bovaAccessToken") !== null &&
      localStorage.getItem("bovaRefreshToken") !== null
    ) {
      window.location.href = "/dashboard/client-cars";
    }
  }, []);

  const handleSubmit = async () => {
    const data = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(
        props.baseUrl + "/auth/signin/",
        {
          email: data.email,
          password: data.password,
        },
        {
          "x-cors-api-key": "temp_7206c40980809b27d68043b8728e93ce",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
          "Content-Type": "application/json",
        }
      );
      const user = jwtDecode(response.data.data["accessToken"]);
      // console.log(user.role);
      if (user.role !== "admin") {
        alert("not admin");
        return;
      }
      props.setAccessToken(response.data.data["accessToken"]);
      props.setRefreshToken(response.data.data["refreshToken"]);
      props.setSignedIn(true);
      localStorage.setItem(
        "bovaAccessToken",
        response.data.data["accessToken"]
      );
      localStorage.setItem(
        "bovaRefreshToken",
        response.data.data["refreshToken"]
      );
      window.location.href = "/dashboard/client-cars";
    } catch (error) {
      console.error(error);
      alert(error.response.data["message"]);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10%",
            padding: "5%",
            borderRadius: "10px",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Avatar
            style={{
              backgroundColor: "##ffffff",
              color: "white",
              marginTop: "2.5%",
              marginBottom: "1%",
              //center
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <img
              style={{
                //center
                marginLeft: "auto",
                marginRight: "auto",
                background: "white",
              }}
              src={logo}
            />
          </Avatar>

          <Typography
            component="h1"
            variant="h5"
            style={{
              //center
              marginBottom: "1%",
              marginTop: "2.5%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Sign in
          </Typography>
          <Input
            margin="normal"
            required
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            autoFocus
            style={{ width: "100%", marginTop: "2.5%", marginBottom: "1%" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            margin="normal"
            required
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            style={{ width: "100%", marginTop: "2.5%", marginBottom: "1%" }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            style={{
              marginTop: "2.5%",
              marginBottom: "1%",
              backgroundColor: "#3f51b5",
              color: "white",
              //center
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onClick={handleSubmit}
          >
            Sign In
          </Button>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
