import React, { useEffect } from "react";
import { useRecoilState, atom } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { IconButton } from "@mui/material";
import { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Input,
  Modal,
  Space,
  Divider,
  AutoComplete,
} from "antd";
import { Layout, Upload, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";

import { currentLangState } from "./Dashboard";
import { LocalizationStrings } from "../utils/localization";
import axios from "axios";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ShowroomCarsInputForm from "./forms/ShowroomCarsInputForm.tsx";
import { SkateboardingSharp } from "@mui/icons-material";

function ShowroomCars(props) {
  let api = useAxios();

  let strings = LocalizationStrings;

  const [currentLang, setCurrentLang] = useRecoilState(currentLangState); // Default language is English (en

  strings.setLanguage(currentLang);

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [Srows, setRows] = useState([]);
  const [editData, setEditData] = useState(null);
  const [inputData, setInputData] = useState(null);
  //   const [usersDropdown, setUsersDropdown] = useState([]);

  const [inputOpen, setInputOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const categoryOptions = [
    { value: "Sedan" },
    { value: "Hatchback" },
    { value: "SUV" },
    { value: "Coupe" },
    { value: "Convertible" },
    { value: "Wagon" },
    { value: "Minivan" },
    { value: "Pickup" },
  ];

  const newsColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 75,
    },
    {
      title: strings.title,
      dataIndex: "title",
      key: "title",
      width: 200,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   width: 100,
    // },
    {
      title: strings.price,
      dataIndex: "price",
      key: "price",
      width: 100,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: strings.productionYear,
      dataIndex: "productionYear",
      key: "productionYear",
      width: 100,
      sorter: (a, b) => a.productionYear - b.productionYear,
      render: (text, record, index) => (
        <>
          {record.productionYear === null ? (
            <Typography.Text type="secondary">-</Typography.Text>
          ) : (
            record.productionYear
          )}
        </>
      ),
    },

    {
      title: strings.options,
      key: "options",
      dataIndex: "options",
      width: 150,
      render: (text, record, index) => (
        <>
          <IconButton
            key="edit"
            type="primary"
            className="tag-primary"
            onClick={() => {
              //console log this row
              console.log(record);
              setEditData(record);
              handleUpdateDialogClickOpen(record.id);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            key="delete"
            type="primary"
            className="tag-primary"
            onClick={() => {
              setDeleteId(record.id);
              handleDeleteButtonClickRow(record);
            }}
          >
            <DeleteOutlined />
          </IconButton>
          <IconButton
            key="info"
            type="primary"
            className="tag-primary"
            onClick={() => {
              navigate(`/showroom-cars/${record.id}`);
            }}
          >
            <InfoCircleOutlined />
          </IconButton>
        </>
      ),
    },
  ];

  let isMounted = true;

  const fetchData = async (p, isSearchEmpty = false) => {
    try {
      if (isMounted) {
        // console.log(headerConfig);
        const response = await api.get(`/admin/showroom-cars`, {
          params: {
            page: p,
            search: isSearchEmpty ? null : search,
            limit: 10,
            sortBy: "createdAt:DESC",
          },
        });
        console.log(response);
        setTotal(response.data.data.meta.totalItems);

        var data = new Array();
        for (let i = 0; i < response.data.data.data.length; i++) {
          data.push(response.data.data.data[i]);
          //add key element to each row
          data[i].key = i;
          // console.log("data: " + i + " " + data);
          // setRows([...Srows, ...data]);
        }
        setRows(data);
        // console.log(Srows);

        // console.log("rows1 \n" + rows1);
      }
    } catch (error) {
      console.error(error);
      // props.logOut();
    }
  };

  useEffect(() => {
    const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
    // console.log(user.role);
    if (user.role !== "admin") {
      alert("not admin");
      props.logOut();
      return;
    }
    setAccessToken(localStorage.getItem("bovaAccessToken"));
    setRefreshToken(localStorage.getItem("bovaRefreshToken"));
    // console.log(accessToken);
    if (
      (localStorage.getItem("bovaAccessToken") === "" &&
        localStorage.getItem("bovaRefreshToken") === "") ||
      (localStorage.getItem("bovaAccessToken") === null &&
        localStorage.getItem("bovaRefreshToken") === null)
    ) {
      navigate("/signin");
    }

    fetchData(page);
    return () => (isMounted = false);
  }, [props.baseUrl]);

  const handleUpdateDialogClickOpen = async (id) => {
    // console.log(id);
    // let data = await getDataById(id);
    // console.log(data);
    // setEditData(data.data);
    setUpdateOpen(true);
  };

  const handleInputDialogClose = () => {
    setInputData({});
    setInputOpen(false);
  };

  const handleUpdateDialogClose = () => {
    setEditData({});
    setUpdateOpen(false);
  };

  const handleDeleteButtonClickRow = (cellValues) => {
    // console.log(event);
    setDeleteId(cellValues.id);
    setDeleteOpen(true);
  };

  const handleDeleteDialogCancel = () => {
    setDeleteOpen(false);
  };

  const handleDeleteDialogConfirm = async () => {
    setDeleteOpen(false);
    const response = await api.delete(`/admin/showroom-cars/${deleteId}`);
    if (response.status === 200) {
      props.openNotification(
        "topRight",
        strings.success,
        strings.car + strings.deletedSuccessfullyF
      );
    }
    console.log(response);
    fetchData(page);
  };

  return (
    <div
      style={{
        width: 1000,
      }}
    >
      <div>
        <Layout
          style={{
            minHeight: "100vh",
            //set a width to the layout
          }}
        >
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title={strings.showroomCars}
                  extra={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        placeholder={strings.search}
                        prefix={<SearchOutlined />}
                        size="small"
                        style={{
                          marginRight: 25,
                        }}
                        onPressEnter={(event) => {
                          setSearch(event.target.value);
                          fetchData(page);
                        }}
                        onChange={(event) => {
                          setSearch(event.currentTarget.value);
                          if (event.target.value === "" || null) {
                            setSearch(null);
                            fetchData(page, true);
                          }
                        }}
                        value={search}
                      />
                      <Button
                        onClick={() => {
                          setInputOpen(true);
                        }}
                      >
                        {strings.createShowroomCar}
                      </Button>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={newsColumns}
                      dataSource={Srows}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                        current: page,
                        //set number of pages
                        total: total,
                        showSizeChanger: false,
                      }}
                      onChange={(pagination) => {
                        setPage(pagination.current);
                        console.log(pagination.current);
                        fetchData(pagination.current);
                      }}
                      size="large"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>

      <Modal
        width={800}
        title={strings.create}
        open={inputOpen}
        onCancel={handleInputDialogClose}
        inputData={inputData}
        footer={null}
      >
        <ShowroomCarsInputForm
          openNotification={props.openNotification}
          fetchData={fetchData}
          page={page}
          open={[inputOpen, setInputOpen]}
          edit={false}
        />
      </Modal>
      {/* //Edit Input */}
      <Modal
        width={800}
        title={strings.edit}
        open={updateOpen}
        footer={null}
        onCancel={handleUpdateDialogClose}
      >
        <ShowroomCarsInputForm
          openNotification={props.openNotification}
          fetchData={fetchData}
          page={page}
          open={[updateOpen, setUpdateOpen]}
          edit={true}
          id={editData?.id}
        />
      </Modal>

      <Modal
        title="Are you sure you want to delete the item?"
        open={deleteOpen}
        onOk={handleDeleteDialogConfirm}
        onCancel={handleDeleteDialogCancel}
      ></Modal>
    </div>
  );
}

export default ShowroomCars;
