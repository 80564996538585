import React from "react";
import {
  Form,
  Input,
  Button,
  Space,
  AutoComplete,
  Divider,
  Upload,
  Select,
  DatePicker,
} from "antd";
import useAxios from "../../utils/useAxios";
import { LocalizationStrings } from "../../utils/localization";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

const strings = LocalizationStrings;

const UsersInputForm = (props) => {
  const api = useAxios();
  const [data, setData] = React.useState({});

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const req = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      password: data?.password,
      role: data?.role,
      phoneNumber: data?.phoneNumber,
      personalIdNumber: data?.personalIdNumber,
      driverLicenseNumber: data?.driverLicenseNumber,
      birthDate: data?.birthDate,
    };
    console.log(req);
    if (props.edit) {
      const response = await api.put("/admin/users/" + props.id, req);
      // console.log(response);
      form.resetFields();
      if (response.status === 200) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.user + strings.updatedSuccessfully
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    } else {
      const response = await api.post("/admin/users/", req);
      // console.log(response);
      form.resetFields();
      if (response.status === 201) {
        props.openNotification(
          "topRight",
          strings.success,
          strings.user + strings.addedSuccessfully
        );
        props.open[1](false);
        setData({});
        props.fetchData(props.page);
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchData = async () => {
    if (props.open[0] === true) {
      if (!props.edit) {
        form.setFieldsValue({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          role: "",
          phoneNumber: "",
          personalIdNumber: "",
          driverLicenseNumber: "",
          birthDate: "",
        });
      } else if (props.edit) {
        api.get("/admin/users/" + props.id).then((res) => {
          //   console.log(res);
          setData({ ...res.data.data });
          //   console.log(res.data.data);
          form.setFieldsValue({
            firstName: res.data.data.firstName,
            lastName: res.data.data.lastName,
            email: res.data.data.email,
            password: res.data.data.password,
            role: res.data.data.role,
            phoneNumber: res.data.data.phoneNumber,
            personalIdNumber: res.data.data.personalIdNumber,
            driverLicenseNumber: res.data.data.driverLicenseNumber,
            birthDate: res.data.data.birthDate
              ? dayjs(res.data.data.birthDate)
              : "",
          });
        });
      }
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [props.open, props.edit]);

  return (
    <div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Form
          colon={false}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          labelAlign="left"
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Divider />
          <Form.Item
            label={strings.firstName}
            name="firstName"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.firstName.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input
              placeholder={strings.firstName}
              onChange={(e) => {
                setData({ ...data, firstName: e.target.value });
              }}
              value={data?.firstName}
            />
          </Form.Item>
          <Form.Item
            label={strings.lastName}
            name="lastName"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.lastName.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input
              placeholder={strings.lastName}
              onChange={(e) => {
                setData({ ...data, lastName: e.target.value });
              }}
              value={data?.lastName}
            />
          </Form.Item>
          <Form.Item
            label={strings.email}
            name="email"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.email.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <Input
              placeholder={strings.email}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              value={data?.email}
            />
          </Form.Item>
          <Form.Item
            label={strings.password}
            name="password"
            rules={[
              {
                required: props.edit ? false : true,
                message:
                  strings.pleaseInput +
                  strings.password.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input.Password
              placeholder={strings.password}
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              value={data?.password}
            />
          </Form.Item>
          <Form.Item
            label={strings.role}
            name="role"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput + strings.role.toLocaleLowerCase() + "!",
              },
            ]}
          >
            <Select
              placeholder={strings.role}
              onChange={(e) => {
                setData({ ...data, role: e });
              }}
              value={data?.role}
              options={[{ value: "admin" }, { value: "user" }]}
            />
          </Form.Item>
          <Form.Item
            label={strings.phoneNumber}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.phoneNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input
              placeholder={strings.phoneNumber}
              onChange={(e) => {
                setData({ ...data, phoneNumber: e.target.value });
              }}
              value={data?.phoneNumber}
            />
          </Form.Item>
          <Form.Item
            label={strings.personalIdNumber}
            name="personalIdNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.personalIdNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input
              placeholder={strings.personalIdNumber}
              onChange={(e) => {
                setData({ ...data, personalIdNumber: e.target.value });
              }}
              value={data?.personalIdNumber}
            />
          </Form.Item>
          <Form.Item
            label={strings.driverLicenseNumber}
            name="driverLicenseNumber"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.driverLicenseNumber.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <Input
              placeholder={strings.driverLicenseNumber}
              onChange={(e) => {
                setData({ ...data, driverLicenseNumber: e.target.value });
              }}
              value={data?.driverLicenseNumber}
            />
          </Form.Item>
          <Form.Item
            label={strings.birthDate}
            name="birthDate"
            rules={[
              {
                required: true,
                message:
                  strings.pleaseInput +
                  strings.birthDate.toLocaleLowerCase() +
                  "!",
              },
            ]}
          >
            <DatePicker
              placeholder={strings.birthDate}
              onChange={(e) => {
                setData({ ...data, birthDate: e });
              }}
              value={data?.birthDate}
            />
          </Form.Item>
          <Divider />

          <Form.Item>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "50px",
                  position: "relative",
                  left: "120%",
                  transform: "translateX(-50%)",
                }}
              >
                {strings.submit}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default UsersInputForm;
